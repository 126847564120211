
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import LoginRouting from "@/components/mixins/LoginRouting";
import Login from "@/components/signIn/Login.vue";
import Reset from "@/components/signIn/Reset.vue";
import Signup from "@/components/signIn/Signup.vue";
import Verify from "@/components/signIn/Verify.vue";
import ResetForm from "@/components/signIn/ResetForm.vue";

@Component({
    components: {
        Login,
        Reset,
        Signup,
        Verify,
        ResetForm,
    },
})
export default class LoginMain extends mixins(LoginRouting) {
    private email = "";

    private updateEmail(val: string): void {
        this.email = val;
    }

    private emailRedirect(payload: { email: string; hash: string }): void {
        this.email = payload.email;
        this.changeHash(payload.hash);
    }
}
