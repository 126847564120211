
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Rules } from "@/helpers/FormRules";
import { Auth } from "aws-amplify";
@Component({
    components: {},
})
export default class Verify extends Vue {
    @Prop()
    userEmail!: string;

    private email = "";
    private confirmationCode = "";
    private errorMessage = "";

    private loading = false;
    private isVerifyFormValid = false;
    private accountConfirmed = false;

    private rules = Rules;

    private async confirmSignup(): Promise<void> {
        this.loading = true;
        this.$emit("loading-start");
        try {
            await Auth.confirmSignUp(this.email, this.confirmationCode);
            /* User will redirected if they have
                the window open they signed up in */
            if (!this.userEmail) {
                this.accountConfirmed = true;
                this.$emit("loading-end");
            } else {
                /* 
                    User has sign up browser window open
                    Don't need to call loading-end because
                    once the user is logged in it will end
                */
                this.$emit("login-link", null);
            }
        } catch (error: any) {
            this.$emit("loading-end");
            this.setErrorMsg(error.message);
        }
        this.loading = false;
    }

    private setErrorMsg(error: any): void {
        this.errorMessage = error;
        setTimeout(() => {
            this.errorMessage = "";
        }, 5000);
    }

    mounted(): void {
        this.onEmailChange(this.userEmail);
    }

    @Watch("userEmail")
    onEmailChange(val: string): void {
        if (val) {
            this.email = val;
        }
    }
}
