
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Decision, App, TableHeader, TableAction, Tab } from "@/graphql/API";
import { mixins } from "vue-class-component";
import Decisions from "@/store/modules/Decisions";
import Apps from "@/store/modules/Apps";
import FlashNotifications from "@/store/modules/FlashNotifications";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import TableManager from "@/components/managers/TableManager.vue";
import TableManagerTools from "@/components/managers/TableManagerTools.vue";
import TableManagerSettings from "@/components/managers/TableManagerSettings.vue";
import AppCreator from "@/components/apps/AppCreator.vue";
import AppTabCreator from "@/components/apps/AppTabCreator.vue";
import AppTabDeletor from "@/components/apps/AppTabDeletor.vue";
import CopyApp from "@/components/apps/CopyApp.vue";
import { Urls } from "@/Urls";
import { DateTimeFormatOptions } from "vue-i18n";
import Workspaces from "@/store/modules/Workspaces";
import TableManagerView from "@/components/managers/TableManagerView.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import UsersModule from "@/store/modules/Users";
import { TableIcons } from "@/IconLibrary";
import PermissionCheck from "@/components/mixins/PermissionCheck";

const decisionsModule = getModule(Decisions);
const appsModule = getModule(Apps);
const workspacesModule = getModule(Workspaces);
const flashModule = getModule(FlashNotifications);
const usersModule = getModule(UsersModule);

@Component({
    components: {
        ViewWrapper,
        TableManager,
        TableManagerSettings,
        AppCreator,
        AppTabDeletor,
        AppTabCreator,
        TableManagerTools,
        TableManagerView,
        VpDialog,
        CopyApp,
    },
})
export default class AppListView extends mixins(PermissionCheck) {
    private selected: number[] = [];
    private settingsMode = true;
    private sideTrigger = "settings";

    private searchTerm = "";
    private deleteOpen = false;
    private viewLoading = false;

    private appDelete: App | null = null;

    private readonly urls = Urls;
    private isCopy = false;

    private headers: { [id: string]: TableHeader } = {
        name: {
            label: "Name",
            property: "title",
            visible: true,
            enabled: true,
            required: true,
            locked: false,
        },
        description: {
            label: "Description",
            property: "description",
            visible: false,
            enabled: false,
            locked: false,
        },
        owner: {
            label: "Owner",
            property: "owner",
            visible: false,
            enabled: false,
            parseFunction: (value: string) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const usersDict = workspacesModule.workspaceUsers;
                return usersDict[value]?.user.email;
            },
            locked: false,
        },
        updated: {
            label: "Last Updated",
            property: "updated",
            visible: false,
            enabled: false,
            parseFunction: (value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const timestamp = require("unix-timestamp");
                const date = timestamp.toDate(value) as Date;
                const dateDisplayOptions: DateTimeFormatOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                };
                return date.toLocaleString("en-US", dateDisplayOptions);
            },
            locked: false,
        },
        created: {
            label: "Created",
            property: "created",
            visible: false,
            enabled: false,
            parseFunction: (value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const timestamp = require("unix-timestamp");
                const date = timestamp.toDate(value) as Date;
                const dateDisplayOptions: DateTimeFormatOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                };
                return date.toLocaleString("en-US", dateDisplayOptions);
            },
            locked: false,
            defaultOff: true,
        },
    };

    private actions: { [id: string]: TableAction } = {
        link: {
            id: "link",
            label: "Copy Link",
            icon: "mdi-link",
            active: false,
            enabled: false,
            locked: false,
        },
        edit: {
            id: "edit",
            label: "Edit",
            icon: "mdi-application-edit",
            active: false,
            enabled: false,
            locked: false,
        },
        duplicate: {
            id: "duplicate",
            label: "Duplicate",
            icon: TableIcons.duplicate,
            active: true,
            enabled: false,
            locked: false,
        },
        launch: {
            id: "launch",
            label: "Launch",
            icon: "mdi-open-in-new",
            active: false,
            enabled: false,
            locked: false,
        },
        delete: {
            id: "delete",
            label: "Delete",
            icon: "mdi-delete",
            active: false,
            enabled: false,
            multi: true,
            locked: false,
        },
    };

    private tools: { [id: string]: TableAction } = {
        new: {
            id: "new",
            label: "New",
            icon: "mdi-plus",
            active: true,
            enabled: false,
            locked: false,
            disabled: (val = this.frameworkWrite) => (val ? true : false),
        },
    };

    /* App Selection */
    get keyedApps(): { [appId: number]: App } {
        return appsModule.keyedApps;
    }

    get selectedApps(): App[] {
        if (this.selected.length) {
            return this.selected.map((id) => this.keyedApps[id]);
        } else {
            return [];
        }
    }

    get selectedApp(): App | null {
        if (this.selectedApps.length) {
            return this.selectedApps[0];
        } else {
            return null;
        }
    }

    /* End of Selection */

    get decisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get selectedAppId(): number | null {
        if (this.selected.length) {
            return this.selected[0];
        } else {
            return null;
        }
    }

    get loading(): boolean {
        return appsModule.appsLoading;
    }

    get appsIndex(): { [id: number]: App } {
        return appsModule.appsList;
    }

    get apps(): App[] {
        if (this.decisionId) {
            return Object.values(this.appsIndex).filter(
                (app) => app.decision_id == this.decisionId
            );
        } else {
            return [];
        }
    }

    get appsList(): App[] {
        if (this.searchTerm && this.searchTerm.length) {
            return this.apps.filter((app) => {
                return app.title
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase());
            });
        } else {
            return this.apps;
        }
    }

    private async selectApp(app: App | null): Promise<void> {
        if (app) {
            this.$router.push({
                path: `${this.urls.APPSLISTEDITOR}/${app.id}`,
            });
        }
        appsModule.selectApp(app ? app.id : null);
    }

    private copyLink(app: App): void {
        if (app) {
            navigator.clipboard.writeText(
                `${window.location.host}${Urls.APPS}/${app.id}`
            );
            flashModule.success({
                message: "Link Copied",
                duration: 3000,
            });
        }
    }

    private editApp(app: App): void {
        if (app) {
            this.selected = [app.id];
            this.settingsMode = false;
            this.isCopy = false;
            this.sideTrigger = `edit-${app.id}`;
        }
    }

    private launchApp(app: App): void {
        if (app) {
            let link = this.$router.resolve({
                path: `${Urls.APPS}/${app.id}`,
            });
            window.open(link.href, "_self");
        }
    }

    private deleteApp(app: App): void {
        if (app) {
            this.selected = [app.id];
            this.deleteOpen = true;
        }
    }

    private createNew(): void {
        this.selected = [];
        this.settingsMode = false;
        this.sideTrigger = "new";
    }

    private async deleteApps(): Promise<void> {
        if (this.selectedApps.length) {
            this.viewLoading = true;
            await Promise.all(
                this.selectedApps.map(async (app) => {
                    if (app) {
                        await appsModule.deleteApp(app.id);
                    }
                })
            );
            this.deleteOpen = false;
            this.viewLoading = false;
        }
    }

    private duplicateApp(app: App): void {
        if (app) {
            this.selected = [app.id];
            this.settingsMode = false;
            this.isCopy = true;
            this.sideTrigger = `duplicate-${app.id}`;
        }
    }

    private toolClick(eventId: string): void {
        switch (eventId) {
            case "new":
                this.isCopy = false;
                this.createNew();
                break;
            case "edit":
                this.isCopy = false;
                this.sideTrigger = "edit-app";
                break;
            case "delete":
                this.isCopy = false;
                if (this.selectedApps.length) {
                    this.deleteOpen = true;
                }
                break;
            case "launch":
                this.isCopy = false;
                if (this.selectedApp) {
                    this.launchApp(this.selectedApp);
                }
                break;
            case "link":
                this.isCopy = false;
                if (this.selectedApp) {
                    this.copyLink(this.selectedApp);
                }
                break;
            case "duplicate":
                this.isCopy = true;
                this.sideTrigger = "duplicate";
                break;
        }
    }

    private completeCopy() {
        this.isCopy = false;
        this.selected = [];
        this.sideTrigger = "sidebar-close";
    }

    @Watch("selectedApp")
    private onWatchSelectedApp() {
        if (!this.selectedApp && this.isCopy) this.isCopy = false;
    }
}
