
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Permission, UserPermission } from "@/graphql/API";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import UsersModule from "@/store/modules/Users";
import { UpdatePermissionInput } from "@/graphql/custom";

const usersModule = getModule(UsersModule);

@Component({
    components: {
        DialogCardTitle,
    },
})
export default class PermissionsEditor extends Vue {
    @Prop({ default: false, type: Boolean })
    edit!: boolean;

    @Prop({ default: null, type: Object })
    user!: UserPermission;

    @Prop()
    permissions!: Permission;

    private open = false;
    private loading = false;
    private currPermission = "write";
    private choicePermission = "write";
    private valuePermission = "write";
    private factorPermission = "write";
    private viewpointPermission = "write";
    private scoreRulePermission = "write";
    private factorInstancePermission = "write";
    private ownerOnly = false;
    private viewpointCount: number | null = null;
    private choiceCount: number | null = null;

    mounted(): void {
        this.saveUserPermissions();
    }

    private permissionOptions: { text: string; id: string }[] = [
        {
            text: "None",
            id: "none",
        },
        {
            text: "Read",
            id: "read",
        },
        {
            text: "Write",
            id: "write",
        },
        {
            text: "Admin",
            id: "admin",
        },
    ];

    get objectPermissions(): { text: string; id: string }[] {
        return this.permissionOptions.filter(
            (permission) => permission.id != "admin"
        );
    }

    get currentUser(): string | null {
        return usersModule.currentUserId;
    }

    @Watch("edit")
    onEditChange(): void {
        this.onUserChange();
    }

    @Watch("user", { immediate: true, deep: true })
    async onUserChange(): Promise<void> {
        if (this.user != null) {
            this.parsePermissions(this.user.permission);
        }
    }

    private parsePermissions(userPermission: Permission): void {
        if (
            !userPermission.read &&
            !userPermission.write &&
            !userPermission.admin
        ) {
            this.currPermission = "none";
        }
        if (userPermission.read) {
            this.currPermission = "read";
        }
        if (userPermission.write) {
            this.currPermission = "write";
        }
        if (userPermission.admin) {
            this.currPermission = "admin";
        }
        if (!userPermission.fc_r && !userPermission.fc_w) {
            this.factorPermission = "none";
        }
        if (userPermission.fc_r) {
            this.factorPermission = "read";
        }
        if (userPermission.fc_w) {
            this.factorPermission = "write";
        }
        if (!userPermission.ch_r && !userPermission.ch_w) {
            this.choicePermission = "none";
        }
        if (userPermission.ch_r) {
            this.choicePermission = "read";
        }
        if (userPermission.ch_w) {
            this.choicePermission = "write";
        }
        if (!userPermission.vp_r && !userPermission.vp_w) {
            this.viewpointPermission = "none";
        }
        if (userPermission.vp_r) {
            this.viewpointPermission = "read";
        }
        if (userPermission.vp_w) {
            this.viewpointPermission = "write";
        }

        if (!userPermission.fi_r && !userPermission.fi_w) {
            this.factorInstancePermission = "none";
        }
        if (userPermission.fi_r) {
            this.factorInstancePermission = "read";
        }
        if (userPermission.fi_w) {
            this.factorInstancePermission = "write";
        }
        if (!userPermission.sc_r && !userPermission.sc_w) {
            this.scoreRulePermission = "none";
        }
        if (userPermission.sc_r) {
            this.scoreRulePermission = "read";
        }
        if (userPermission.sc_w) {
            this.scoreRulePermission = "write";
        }
        if (!userPermission.va_r && !userPermission.va_w) {
            this.scoreRulePermission = "none";
        }
        if (userPermission.va_r) {
            this.valuePermission = "read";
        }
        if (userPermission.va_w) {
            this.valuePermission = "write";
        }

        this.viewpointCount = userPermission.vp_c;
        this.choiceCount = userPermission.ch_c;

        this.ownerOnly = userPermission.owner_only;
    }

    private parsePermissionsInput(permissions: UpdatePermissionInput): void {
        if (this.viewpointCount && this.viewpointCount >= 0) {
            permissions.vp_c = this.viewpointCount;
        }
        if (this.viewpointCount && this.viewpointCount < 0) {
            this.viewpointCount = null;
            permissions.vp_c = undefined;
        }

        if (this.choiceCount && this.choiceCount >= 0) {
            permissions.ch_c = this.choiceCount;
        }
        if (this.choiceCount && this.choiceCount < 0) {
            this.choiceCount = null;
            permissions.vp_c = undefined;
        }
        switch (this.currPermission) {
            case "read":
                permissions.read = true;
                break;
            case "write":
                permissions.write = true;
                permissions.read = true;
                break;
            case "admin":
                permissions.admin = true;
                permissions.write = true;
                permissions.read = true;
                break;
            default:
                permissions.read = false;
                permissions.write = false;
                permissions.admin = false;

        }
        if (this.factorPermission === "write") {
            permissions.fc_w = true;
            permissions.fc_r = true;
        }
        if (this.factorPermission === "read") {
            permissions.fc_r = true;
        }
        if (this.factorPermission === "none") {
            permissions.fc_r = false;
            permissions.fc_w = false;
        }
        if (this.choicePermission === "write") {
            permissions.ch_w = true;
            permissions.ch_r = true;
        }
        if (this.choicePermission === "read") {
            permissions.ch_r = true;
        }
        if (this.choicePermission === "none") {
            permissions.ch_w = false;
            permissions.ch_r = false;
        }
        if (this.valuePermission === "write") {
            permissions.va_r = true;
            permissions.va_w = true;
        }
        if (this.valuePermission === "read") {
            permissions.va_r = true;
        }
        if (this.valuePermission === "none") {
            permissions.va_r = false;
            permissions.va_w = false;
        }
        if (this.viewpointPermission === "write") {
            permissions.vp_w = true;
            permissions.vp_r = true;
        }
        if (this.viewpointPermission === "read") {
            permissions.vp_r = true;
        }
        if (this.viewpointPermission === "none") {
            permissions.vp_w = false;
            permissions.vp_r = false;
        }
        if (this.scoreRulePermission === "write") {
            permissions.sc_w = true;
            permissions.sc_r = true;
        }
        if (this.scoreRulePermission === "read") {
            permissions.sc_r = true;
        }
        if (this.scoreRulePermission === "none") {
            permissions.sc_w = false;
            permissions.sc_r = false;
        }
        if (this.factorInstancePermission === "write") {
            permissions.fi_w = true;
            permissions.fi_r = true;
        }
        if (this.factorInstancePermission === "read") {
            permissions.fi_r = true;
        }
        if (this.factorInstancePermission === "none") {
            permissions.fi_w = false;
            permissions.fi_r = false;
        }
        permissions.owner_only = this.ownerOnly;
    }

    private async saveUserPermissions(): Promise<void> {
        const permissionsUpdateInput: UpdatePermissionInput = {
            read: false,
            write: false,
            admin: false,
            owner_only: false,
            fc_r: false,
            fc_w: false,
            vp_r: false,
            vp_w: false,
            fi_r: false,
            fi_w: false,
            ch_r: false,
            ch_w: false,
            va_r: false,
            va_w: false,
            sc_r: false,
            sc_w: false,
            vp_c: undefined,
            ch_c: undefined,
        };
        this.parsePermissionsInput(permissionsUpdateInput);
        this.$emit("permissions-changed", permissionsUpdateInput);
    }
}
