
import { Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Viewpoint, TableHeader, TableAction } from "@/graphql/API";
import { mixins } from "vue-class-component";
import TableBase from "@/components/mixins/TableBase";
import Viewpoints from "@/store/modules/Viewpoints";
import Decisions from "@/store/modules/Decisions";
import FlashNotifications from "@/store/modules/FlashNotifications";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import TableManager from "@/components/managers/TableManager.vue";
import TableManagerSettings from "@/components/managers/TableManagerSettings.vue";
import ViewpointCreator from "@/components/viewpoints/ViewpointCreator.vue";
import TableManagerTools from "@/components/managers/TableManagerTools.vue";
import TableManagerView from "@/components/managers/TableManagerView.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import { DateTimeFormatOptions } from "vue-i18n";
import Workspaces from "@/store/modules/Workspaces";

const viewpointModule = getModule(Viewpoints);
const decisionsModule = getModule(Decisions);
const flashModule = getModule(FlashNotifications);
const workspacesModule = getModule(Workspaces);
@Component({
    components: {
        ViewWrapper,
        TableManager,
        TableManagerSettings,
        TableManagerTools,
        ViewpointCreator,
        TableManagerView,
        VpDialog,
    },
})
export default class ViewpointsView extends mixins(TableBase) {
    private editorOpen = false;
    private selected: number[] = [];

    private searchTerm = "";
    private sideTrigger = "settings";

    private deleteOpen = false;

    private editItem: Viewpoint | null = null;
    private isCopy = false;
    private isDelete = false;

    private viewLoading = false;

    private headers: { [id: string]: TableHeader } = {
        name: {
            label: "Name",
            property: "name",
            visible: true,
            required: true,
            enabled: true,
            locked: false,
        },
        description: {
            label: "Description",
            property: "description",
            visible: false,
            enabled: false,
            locked: false,
        },
        updated: {
            label: "Last Updated",
            property: "updated",
            visible: false,
            enabled: false,
            parseFunction: (value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const timestamp = require("unix-timestamp");
                const date =  timestamp.toDate(value) as Date;
                const dateDisplayOptions: DateTimeFormatOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                };
                return date.toLocaleString("en-US", dateDisplayOptions)
            },
        },
        created: {
            label: "Created",
            property: "created",
            visible: false,
            enabled: false,
            parseFunction: (value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const timestamp = require("unix-timestamp");
                const date =  timestamp.toDate(value) as Date;
                const dateDisplayOptions: DateTimeFormatOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                };
                return date.toLocaleString("en-US", dateDisplayOptions)
            },
            defaultOff: true,
        },
        owner: {
            label: "Owner",
            property: "owner",
            visible: true,
            enabled: false,
            parseFunction: (value: string) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const usersDict = workspacesModule.workspaceUsers;
                return usersDict[value]?.user.email;
            },
            defaultOff: true,
        },
    };

    private actions: { [id: string]: TableAction } = {
        edit: {
            id: "edit",
            label: "Edit",
            icon: "mdi-pencil",
            active: false,
            enabled: false,
            locked: false,
        },
        duplicate: {
            id: "duplicate",
            label: "Duplicate",
            icon: "mdi-content-copy",
            active: false,
            enabled: false,
            locked: false,
        },
        delete: {
            id: "delete",
            label: "Delete",
            icon: "mdi-delete",
            multi: true,
            active: false,
            enabled: false,
            locked: false,
        },
        setViewpoint: {
            id: "setViewpoint",
            label: "Set As Default",
            icon: "mdi-reload-alert",
            active: false,
            enabled: false,
            locked: false,
            toolTip: "Set as default",
        },
    };

    private tools: { [id: string]: TableAction } = {
        new: {
            id: "new",
            label: "New",
            icon: "mdi-plus",
            active: false,
            enabled: false,
            locked: false,
        },
    };

    get viewpoints(): Viewpoint[] {
        return viewpointModule.viewpoints;
    }

    get viewpointsList(): { [id: number]: Viewpoint } {
        return viewpointModule.viewpointList;
    }

    /* Viewpoint Selection */
    get selectedViewpoints(): Viewpoint[] {
        if (this.selected.length) {
            return this.selected.map((id) => this.viewpointsList[id]);
        } else {
            return [];
        }
    }

    get selectedViewpoint(): Viewpoint | null {
        if (this.selectedViewpoints.length) {
            return this.selectedViewpoints[0];
        } else {
            return null;
        }
    }

    /* End of Viewpoint Selection */

    get loading(): boolean {
        return decisionsModule.dataLoading;
    }

    private createNew(): void {
        this.selected = [];
        this.isCopy = false;
        this.sideTrigger = "new-viewpoint";
    }

    private openViewpointEditor(viewpoint: Viewpoint): void {
        if (viewpoint) {
            this.isCopy = false;
            this.openEditor(viewpoint);
        }
    }

    private openEditor(viewpoint: Viewpoint): void {
        if (viewpoint) {
            this.selected = [viewpoint.id];
            this.editorOpen = true;
            this.sideTrigger = `edit-${viewpoint.id}`;
        }
    }

    private duplicateViewpoint(viewpoint: Viewpoint): void {
        this.isCopy = true;
        this.openEditor(viewpoint);
    }

    private deleteViewpoint(viewpoint: Viewpoint): void {
        if (viewpoint) {
            this.selected = [viewpoint.id];
            this.deleteOpen = true;
        }
    }

    private async setInstanceAsDefault(viewpoint: Viewpoint): Promise<void> {
        if (viewpoint) {
            this.selected = [viewpoint.id];
            try {
                await viewpointModule.setInstanceAsDefault(viewpoint.id);
                flashModule.success({
                    message: `${viewpoint.name} set as default`,
                    duration: 3000,
                });
            } catch (e) {
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
                flashModule.error({
                    message: "Something went wrong.",
                    duration: 3000,
                });
            }
        }
    }

    private async deleteViewpoints(): Promise<void> {
        if (this.selectedViewpoints.length) {
            this.viewLoading = true;
            await Promise.all(
                this.selectedViewpoints.map(async (viewpoint) => {
                    if (viewpoint) {
                        await viewpointModule.deleteViewpoint(viewpoint.id);
                    }
                })
            );
            this.selected = [];
            this.deleteOpen = false;
            this.viewLoading = false;
        }
    }

    private toolClick(eventId: string): void {
        switch (eventId) {
            case "new":
                this.createNew();
                break;
            case "edit":
                if (this.selectedViewpoint) {
                    this.isCopy = false;
                    this.sideTrigger = `edit-${this.selectedViewpoint.id}`;
                }
                break;
            case "duplicate":
                if (this.selectedViewpoint) {
                    this.isCopy = true;
                    this.editorOpen = true;
                    this.sideTrigger = `duplicate-${this.selectedViewpoint.id}`;
                }
                break;
            case "delete":
                this.deleteOpen = true;
                break;
            case "setViewpoint":
                if (this.selectedViewpoint) {
                    this.setInstanceAsDefault(this.selectedViewpoint);
                }
                break;
        }
    }
}
