
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { User, Workspace, App } from "@/graphql/API";
import { Urls } from "@/Urls";
import Workspaces from "@/store/modules/Workspaces";
import Apps from "@/store/modules/Apps";

const workspaceModule = getModule(Workspaces);
const appsModule = getModule(Apps);

@Component({
    components: {},
})
export default class WorkspaceInvite extends Vue {
    @Prop()
    user!: User;

    @Prop()
    workId!: number;

    @Prop()
    appId!: number | null;

    private readonly URLS = Urls;

    get userLabel(): string {
        if (this.user) {
            if (this.user.first) {
                return this.user.last
                    ? `${this.user.first} ${this.user.last}`
                    : this.user.first;
            } else {
                return this.user.email ? this.user.email : "User";
            }
        } else {
            return "User not found";
        }
    }

    get invitedWorkspace(): Workspace | null {
        if (this.workId && workspaceModule.workspaces[this.workId]) {
            return workspaceModule.workspaces[this.workId];
        } else {
            return null;
        }
    }

    get app(): App | null {
        if (this.appId && appsModule.keyedApps[this.appId]) {
            return appsModule.keyedApps[this.appId];
        } else {
            return null;
        }
    }

    private async openWorkspace(): Promise<void> {
        if (this.workId) {
            if (this.app) {
                this.$router.push({
                    path: `${Urls.APPS}/${this.app.id}`,
                });
            } else {
                await workspaceModule.selectWorkspace(this.workId);
                this.$router.push({
                    path: Urls.HOME,
                });
            }
        }
        console.log("workspace");
    }
}
