
import { Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import { App, Tab, AppOptions, Permission } from "@/graphql/API";
import AppUrl from "@/components/mixins/AppUrl";
import Apps from "@/store/modules/Apps";
import Decisions from "@/store/modules/Decisions";
import Workspaces from "@/store/modules/Workspaces";
import AppInterface from "@/layouts/AppInterface.vue";
import AppDisplay from "@/components/apps/AppDisplay.vue";
import CompareView from "@/views/CompareView.vue";
import ChoiceEditor from "@/views/ChoiceEditor.vue";
import ScoreClassTable from "@/views/ScoreClassTable.vue";
import ChoicesView from "@/views/ChoicesView.vue";
import RankView from "@/views/RankView.vue";
import ModelView from "@/views/ModelView.vue";
import ViewpointsView from "@/views/ViewpointsView.vue";
import UnitView from "@/views/UnitView.vue";
import FactorAIView from "@/views/FactorAIView.vue";
import TabLoading from "@/components/apps/tabs/TabLoading.vue";
import TabError from "@/components/apps/tabs/TabError.vue";
import UsersView from "@/views/UsersView.vue";
import Logo from "@/components/ui/Logo.vue";
import Choices from "@/store/modules/Choices";
import UsersModule from "@/store/modules/Users";
import Viewpoints from "@/store/modules/Viewpoints";
import { Urls } from "@/Urls";
import {
    isChoiceWriteValid,
    isViewpointWriteValid,
} from "@/helpers/PermissionsHelper";

const appsModule = getModule(Apps);
const workspaceModule = getModule(Workspaces);
const decisionsModule = getModule(Decisions);
const usersModule = getModule(UsersModule);
const choiceModule = getModule(Choices);
const viewpointModule = getModule(Viewpoints);

@Component({
    components: {
        AppInterface,
        AppDisplay,
        CompareView,
        ChoiceEditor,
        RankView,
        TabLoading,
        ModelView,
        TabError,
        ChoicesView,
        ScoreClassTable,
        ViewpointsView,
        UnitView,
        UsersView,
        Logo,
        FactorAIView,
    },
})
export default class AppView extends mixins(AppUrl) {
    private selectedApp: App | null = null;
    private appLoading = false;
    private tabLoading = false;
    private decisionLoading = false;

    private leftMenuOpen = true;
    private mobileOpen = false;

    private activeTabs: number[] = [];

    private readonly URLS = Urls;

    get loadingOverlay(): boolean {
        return this.appLoading || this.decisionLoading;
    }

    get decisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get workspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get hideMenu(): boolean {
        if (this.selectedApp) {
            if (this.selectedApp.tabs && this.selectedApp.tabs.length == 1) {
                return true;
            }
        }
        return false;
    }

    get image(): string | null {
        return "https://lirp.cdn-website.com/43812060/dms3rep/multi/opt/image-home-1920w.png";
    }

    get appOptions(): AppOptions {
        if (this.selectedApp && this.selectedApp.json.length) {
            return JSON.parse(this.selectedApp.json);
        } else {
            return {};
        }
    }

    get appTitle(): string | null {
        return null;
        // return this.selectedApp ? this.selectedApp.title : null;
    }

    get pageTitle(): string {
        if (this.selectedApp) {
            if (this.currTab) {
                return `${this.selectedApp.title} | ${this.currTab.title}`;
            } else {
                return `${this.selectedApp.title}`;
            }
        } else {
            return process.env.VUE_APP_TITLE
                ? process.env.VUE_APP_TITLE
                : "Viewpoint AI";
        }
    }

    get appWorkspaceId(): number | null {
        return null;
    }

    get appDecisionId(): number | null {
        return this.selectedApp ? this.selectedApp.decision_id : null;
    }

    get tabs(): Tab[] {
        if (this.appIdParam && this.appTabsIndex[this.appIdParam]) {
            return this.appTabsIndex[this.appIdParam];
        } else {
            return [];
        }
    }

    get firstTab(): Tab | null {
        return this.tabs.length ? this.tabs[0] : null;
    }

    get tabIndex(): { [id: number]: Tab } | null {
        if (this.selectedApp) {
            return this.selectedApp.tabs.reduce((acc, a) => {
                return {
                    ...acc,
                    [a.id]: a,
                };
            }, {});
        } else {
            return null;
        }
    }

    get selectedTab(): Tab | null {
        if (this.tabIndex && this.tabIdParam) {
            return this.tabIndex[this.tabIdParam]
                ? this.tabIndex[this.tabIdParam]
                : null;
        } else {
            return null;
        }
    }

    get userEdit(): boolean {
        if (usersModule.currentPermissions) {
            return usersModule.currentPermissions.write;
        } else {
            return false;
        }
    }

    get showTabEdit(): boolean {
        if (this.tabIdParam && this.userEdit) {
            return true;
        } else {
            return false;
        }
    }

    get editorLink(): string {
        return `${window.location.origin}${Urls.TABEDITOR}/${this.appIdParam}/${this.tabIdParam}`;
    }

    private setTabId(id: number): void {
        if (this.appIdParam) {
            this.$router.push({
                name: "AppView",
                params: {
                    appId: this.appIdParam.toString(),
                    tabId: id.toString(),
                },
            });
        }
    }

    async mounted(): Promise<void> {
        this.onPageTitleChange(this.pageTitle);
        await this.onAppChange();
        await this.onTabChange();
    }

    @Watch("tabIdParam")
    async onTabChange(): Promise<void> {
        if (this.tabIdParam) {
            this.tabLoading = true;
            if (!this.activeTabs.includes(this.tabIdParam)) {
                this.activeTabs.push(this.tabIdParam);

                await Promise.all([
                    appsModule.getTabFactors(this.tabIdParam),
                    appsModule.getTabChoices(this.tabIdParam),
                    appsModule.getTabViewpoints(this.tabIdParam),
                ]);
            }
            this.tabLoading = false;
        }
    }

    @Watch("appIdParam")
    async onAppChange(): Promise<void> {
        if (this.appIdParam) {
            try {
                this.appLoading = true;
                this.selectedApp = await appsModule.getApp(this.appIdParam);
                await this.loadObjects();
                this.appLoading = false;
            } catch (e) {
                console.log("ERROR:");
                console.log(e);
                this.appLoading = false;
            }
        }
    }

    @Watch("appDecisionId")
    async onAppDecisionChange(): Promise<void> {
        if (this.appDecisionId) {
            this.decisionLoading = true;
            const decision = await decisionsModule.fetchDecision(
                this.appDecisionId
            );
            if (decision) {
                await Promise.all([
                    await workspaceModule.selectWorkspace(
                        decision.workspace_id
                    ),
                    await decisionsModule.selectDecision(decision.id),
                ]);
            }
            this.decisionLoading = false;
        }
    }

    private async loadObjects(): Promise<void> {
        const permissions = await usersModule.fetchMyPermissions(
            this.workspaceId
        );
        if (permissions) {
            await Promise.all([this.loadChoices(), this.loadViewpoints()]);
        }
    }

    private async getChoices(): Promise<number> {
        if (this.appDecisionId && usersModule.currentUserId) {
            const choices = await choiceModule.fetchChoices(this.appDecisionId);
            return choices.filter(
                (choice) => choice.owner === usersModule.currentUserId
            ).length;
        } else {
            return 0;
        }
    }

    private async getViewpoints(): Promise<number> {
        if (this.appDecisionId && usersModule.currentUserId) {
            const viewpoints = await viewpointModule.fetchViewpoints(
                this.appDecisionId
            );
            return viewpoints.filter(
                (viewpoint) => viewpoint.owner === usersModule.currentUserId
            ).length;
        }
        return 0;
    }

    private async loadChoices(): Promise<void> {
        const userCreated = await this.getChoices();
        if (this.selectedApp && this.selectedApp.json) {
            const appOptions = JSON.parse(this.selectedApp.json);
            if (userCreated < 1 && appOptions.userChoice) {
                /* No User created Choice and one should be created */
                if (
                    usersModule.currentUserId &&
                    isChoiceWriteValid(usersModule.currentUserId)
                ) {
                    /* User has permission to create a choice */
                    await choiceModule.createChoiceL({
                        name: `${usersModule.currentUser?.first} ${usersModule.currentUser?.last}`,
                        decision_id: this.selectedApp.decision_id,
                    });
                }
            }
        }
    }

    private async loadViewpoints(): Promise<void> {
        const userCreated = await this.getViewpoints();
        if (this.selectedApp && this.selectedApp.json) {
            const appOptions = JSON.parse(this.selectedApp.json);
            if (userCreated < 1 && appOptions.userViewpoint) {
                /* No User created Viewpoint and one should be created */
                await viewpointModule.createViewpointL({
                    name: `${usersModule.currentUser?.first} ${usersModule.currentUser?.last}`,
                    decisionId: this.selectedApp.decision_id,
                });
            }
        }
    }

    private async createAppChoice(): Promise<void> {
        if (getModule(UsersModule).currentUser && this.appDecisionId) {
            const permissionPromise = usersModule.fetchMyPermissions(
                this.workspaceId
            );
            const choicePromise = getModule(Choices).fetchChoices(
                this.appDecisionId
            );
            await Promise.all([permissionPromise, choicePromise]);
            await usersModule.fetchMyPermissions(this.workspaceId);
            if (this.selectedApp) {
                const name =
                    usersModule.currentUser?.first +
                    " " +
                    usersModule.currentUser?.last;
                if (
                    JSON.parse(this.selectedApp.json).userChoice &&
                    name &&
                    usersModule.currentUserId &&
                    isChoiceWriteValid(usersModule.currentUserId)
                ) {
                    await getModule(Choices).createChoiceL({
                        name: name,
                        decision_id: this.selectedApp.decision_id,
                    });
                }
            }
        }
    }

    private async createAppViewpoint(): Promise<void> {
        if (getModule(UsersModule).currentUser && this.appDecisionId) {
            const permissionPromise = usersModule.fetchMyPermissions(
                this.workspaceId
            );
            const viewpointPromise = getModule(Viewpoints).fetchViewpoints(
                this.appDecisionId
            );
            await Promise.all([permissionPromise, viewpointPromise]);
            if (this.selectedApp) {
                const name =
                    getModule(UsersModule).currentUser?.first +
                    " " +
                    getModule(UsersModule).currentUser?.last;
                if (
                    JSON.parse(this.selectedApp.json).userViewpoint &&
                    usersModule.currentUserId &&
                    isViewpointWriteValid(usersModule.currentUserId)
                ) {
                    this.appLoading = true;
                    await getModule(Viewpoints).createViewpointL({
                        name: name,
                        decisionId: this.selectedApp.decision_id,
                    });
                    this.appLoading = false;
                }
            }
        }
    }

    private openEditLink(): void {
        if (this.appIdParam && this.tabIdParam) {
            let link = this.$router.resolve({
                path: `${Urls.TABEDITOR}/${this.appIdParam}/${this.tabIdParam}`,
            });
            window.open(link.href, "_blank");
        }
    }

    @Watch("pageTitle")
    onPageTitleChange(val: string): void {
        document.title = val;
    }

    @Watch("firstTab", { immediate: true, deep: true })
    onFirstTabChange(): void {
        if (this.firstTab && !this.tabIdParam) {
            this.setTabId(this.firstTab.id);
        }
    }

    @Watch("hideMenu")
    onHideMenuChange(val: boolean): void {
        if (val) {
            this.leftMenuOpen = false;
        }
    }
}
