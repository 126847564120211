
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    TableHeader,
    TableAction,
    UserPermission,
    User,
    UserDisplay,
    PermissionIdInput,
    Permission,
    Invite,
} from "@/graphql/API";
import { mixins } from "vue-class-component";
import TableBase from "@/components/mixins/TableBase";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import TableManager from "@/components/managers/TableManager.vue";
import TableManagerSettings from "@/components/managers/TableManagerSettings.vue";
import DecisionEditor from "@/components/decisions/DecisionEditor.vue";
import TableManagerTools from "@/components/managers/TableManagerTools.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";
import Workspaces from "@/store/modules/Workspaces";
import { InviteUserLInput } from "@/graphql/custom";
import UsersModule from "@/store/modules/Users";
import UserEmailInput from "@/components/ui/UserEmailInput.vue";
import FlashNotifications from "@/store/modules/FlashNotifications";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import UserEditor from "@/components/users/UserEditor.vue";
import TableManagerView from "@/components/managers/TableManagerView.vue";
import AccessDenied from "@/components/users/AccessDenied.vue";
import InviteManager from "@/components/Invite/InviteManager.vue";

const workspaceModule = getModule(Workspaces);
const userModule = getModule(UsersModule);
const flashModule = getModule(FlashNotifications);
@Component({
    components: {
        ViewWrapper,
        TableManager,
        TableManagerSettings,
        DecisionEditor,
        TableManagerTools,
        VpDialog,
        UserEmailInput,
        UserEditor,
        TableManagerView,
        AccessDenied,
        InviteManager,
    },
})
export default class UsersView extends mixins(TableBase) {
    @Prop()
    propWorkspaceId!: number;

    private selected: string[] = [];
    private selectedUser: UserPermission | null = null;

    private settingsMode = true;
    private isCopy = false;
    private searchTerm = "";

    private deleteOpen = false;
    private sideTrigger = "settings";
    private users: UserPermission[] = [];
    private pending: Invite[] = [];
    private loading = false;
    private loadingOverlay = false;
    private errorThrown = false;

    private showInvites = false;

    private headers: { [id: string]: TableHeader } = {
        first: {
            label: "First Name",
            property: "first",
            visible: true,
            required: true,
            enabled: true,
            locked: false,
        },
        last: {
            label: "Last Name",
            property: "last",
            visible: false,
            enabled: false,
            locked: false,
        },
        email: {
            label: "Email",
            property: "email",
            visible: false,
            enabled: false,
            locked: false,
        },
        read: {
            label: "Read",
            property: "read",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
        write: {
            label: "Write",
            property: "write",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
        admin: {
            label: "Admin",
            property: "admin",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
        factorRead: {
            label: "Factor Read",
            property: "fc_r",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
        factorWrite: {
            label: "Factor Write",
            property: "fc_w",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
        choiceRead: {
            label: "Choice Read",
            property: "ch_r",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
        choiceWrite: {
            label: "Choice Write",
            property: "ch_w",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
        viewpointRead: {
            label: "Viewpoint Read",
            property: "vp_r",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
        viewpointWrite: {
            label: "Viewpoint Write",
            property: "vp_w",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
    };

    private actions: { [id: string]: TableAction } = {
        edit: {
            id: "edit",
            label: "Edit",
            icon: "mdi-pencil",
            active: true,
            enabled: true,
        },
        delete: {
            id: "delete",
            label: "Delete",
            icon: "mdi-delete",
            multi: true,
            active: true,
            disabled: (value: string) => {
                return value === this.currentUserId;
            },
            enabled: true,
        },
    };

    private tools: { [id: string]: TableAction } = {
        new: {
            id: "new",
            label: "New",
            icon: "mdi-plus",
            active: true,
            enabled: false,
        },
    };

    get currentUserId(): string | undefined {
        return userModule.currentUser?.id;
    }

    get selectedId(): string | null {
        if (this.selected.length) {
            return this.selected[0];
        } else {
            return null;
        }
    }

    get workspaceId(): number | null {
        if (this.propWorkspaceId) {
            return this.propWorkspaceId;
        }
        return workspaceModule.selectedWorkspaceId;
    }

    get userList(): UserPermission[] {
        if (this.searchTerm && this.searchTerm.length) {
            return this.users.filter((user) => {
                return this.filterUser(this.searchTerm.toLowerCase(), {
                    email: user.user.email,
                    first: user.user.first,
                    last: user.user.last,
                });
            });
        } else {
            return this.users;
        }
    }

    get userItemList(): UserDisplay[] {
        return this.userList.map((item) => {
            return {
                id: item.user.id,
                first: item.user.first,
                last: item.user.last,
                email: item.user.email,
                userPermission: item,
                ...item.permission,
            };
        });
    }

    get keyedUsers(): { [id: string]: UserPermission } {
        return this.users.reduce((acc, a) => {
            return {
                ...acc,
                [a.user.id]: a,
            };
        }, {});
    }

    get selectedUsers(): UserPermission[] {
        if (this.selected) {
            return this.selected.map((id) => this.keyedUsers[id]);
        } else {
            return [];
        }
    }

    get selectedItem(): UserPermission | null {
        if (this.selectedUsers.length) {
            return this.selectedUsers[0];
        } else {
            return null;
        }
    }

    /* search string should already be lower case */
    private filterUser(
        search: string,
        user: {
            email?: string;
            first?: string;
            last?: string;
        }
    ): boolean {
        if (user.email && user.email.toLowerCase().includes(search)) {
            return true;
        } else if (
            user.first &&
            user.first.toLocaleLowerCase().includes(search)
        ) {
            return true;
        } else if (
            user.last &&
            user.last.toLocaleLowerCase().includes(search)
        ) {
            return true;
        } else {
            return false;
        }
    }

    private async inviteUserEmail(userInvite: InviteUserLInput) {
        if (this.workspaceId) {
            this.loading = true;
            userInvite.permission.workspace_id = this.workspaceId;
            try {
                const res = await userModule.inviteUserL(userInvite);
                if (res) {
                    flashModule.success({
                        message: `Invite sent to ${userInvite.users.map(
                            (user) => `${user.email} `
                        )}`,
                        duration: 3000,
                    });
                } else {
                    flashModule.error({
                        message:
                            "Something went wrong. Please refresh and try again.",
                        duration: 3000,
                    });
                }
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
            this.onWatchWorkspaceId();
        }
    }

    private toggleDelete(): void {
        this.deleteOpen = true;
    }

    private async deleteUsers(): Promise<void> {
        if (this.selectedUsers.length) {
            this.loading = true;
            await Promise.all(
                this.selectedUsers.map(async (user) => {
                    if (user) {
                        await this.removeUser(user);
                    }
                })
            );
            this.selected = [];
            this.loading = false;
        }
    }

    private async removeUser(user: UserPermission) {
        try {
            const permissionId: PermissionIdInput = {
                workspace_id: user.permission.workspace_id,
                user_id: user.user.id,
            };

            const removedUser = await getModule(UsersModule).removePermissions(
                permissionId
            );
            if (removedUser) {
                const toDeleteUserIndex = this.users.findIndex(
                    (searchUser) => searchUser.user.id === user.user.id
                );
                this.users.splice(toDeleteUserIndex, 1);
            }
            this.onWatchWorkspaceId();
        } catch (e) {
            this.errorThrown = true;
            const err = e as GraphQLResult<any>;
            const message = err?.errors
                ? err.errors[0].message
                : "Something went wrong.";
            console.log("%cError:", "color: red; font-weight: bold;");
            console.log(e);
            flashModule.error({
                message: message,
                duration: 3000,
            });
        }
    }

    private selectEditUser(user: UserDisplay) {
        this.selected = [user.id];
        this.sideTrigger = `user-${user.id}`;
    }

    private updatePermission(user: UserPermission) {
        this.settingsMode = true;
        const foundIndex = this.users.findIndex(
            (searchUser) => user.user.id === searchUser.user.id
        );

        if (foundIndex >= 0) {
            this.users[foundIndex].permission = { ...user.permission };
        }
    }

    private toolClick(eventId: string): void {
        switch (eventId) {
            case "new":
                this.sideTrigger = "new";
                break;
            case "edit":
                if (this.selectedUser) {
                    this.sideTrigger = `user-${this.selectedUser.user.id}`;
                }
                break;
            case "delete":
                if (this.selectedUser) {
                    this.deleteOpen = true;
                }
                break;
        }
    }

    private toggleInvites(): void {
        this.showInvites = true;
        this.sideTrigger = "";
    }

    async mounted(): Promise<void> {
        this.onWatchWorkspaceId();
    }

    private async deletedInvites(invites: Invite[]): Promise<void> {
        console.log("Deleted");
        console.log(invites);
    }

    @Watch("workspaceId")
    async onWatchWorkspaceId(): Promise<void> {
        if (this.workspaceId) {
            this.loadingOverlay = true;
            try {
                [this.users, this.pending] = await Promise.all([
                    await GRAPHQL_API.getWorkspaceUsers(this.workspaceId),
                    await userModule.fetchPendingInvites(this.workspaceId),
                ]);
            } catch (e) {
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
            }
            this.loadingOverlay = false;
        }
    }

    @Watch("selected", { deep: true, immediate: true })
    onSelectedIdChange(): void {
        const foundIndex = this.users.findIndex(
            (searchUser) => this.selected[0] === searchUser.user.id
        );

        if (foundIndex >= 0) {
            this.selectedUser = this.users[foundIndex];
        }
    }
}
