
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Choice, TableHeader, TableAction, Tab } from "@/graphql/API";
import { mixins } from "vue-class-component";
import { saveTab } from "@/helpers/TabHelper";
import TableBase from "@/components/mixins/TableBase";
import { TableIcons } from "@/IconLibrary";
import Choices from "@/store/modules/Choices";
import Decisions from "@/store/modules/Decisions";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import TableManager from "@/components/managers/TableManager.vue";
import TableManagerSettings from "@/components/managers/TableManagerSettings.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import ChoiceCreator from "@/components/choices/ChoiceCreator.vue";
import TableManagerTools from "@/components/managers/TableManagerTools.vue";
import ViewAppCreator from "@/components/apps/ViewAppCreator.vue";
import TableManagerView from "@/components/managers/TableManagerView.vue";
import Workspaces from "@/store/modules/Workspaces";
import RecursiveChoiceFactors from "@/components/choices/editor/RecursiveChoice.vue";
import { DateTimeFormatOptions } from "vue-i18n";
import AccessDenied from "@/components/users/AccessDenied.vue";

const choiceModule = getModule(Choices);
const decisionsModule = getModule(Decisions);
const workspacesModule = getModule(Workspaces);
@Component({
    components: {
        ViewWrapper,
        TableManager,
        TableManagerSettings,
        VpDialog,
        ChoiceCreator,
        TableManagerTools,
        ViewAppCreator,
        TableManagerView,
        RecursiveChoiceFactors,
        AccessDenied,
    },
})
export default class ChoicesView extends mixins(TableBase) {
    private editorOpen = false;
    private selected: number[] = [];

    private searchTerm = "";
    private sideTrigger = "settings";

    private editItem: Choice | null = null;
    private isCopy = false;
    private isDelete = false;
    private loading = false;
    private deleteOpen = false;

    private modalOpen = false;

    private headers: { [id: string]: TableHeader } = {
        name: {
            label: "Name",
            property: "name",
            visible: false,
            required: true,
            enabled: false,
            locked: false,
        },
        description: {
            label: "Description",
            property: "description",
            visible: false,
            enabled: false,
            locked: false,
        },
        owner: {
            label: "Owner",
            property: "owner",
            visible: false,
            enabled: false,
            locked: false,
            parseFunction: (value: string) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const usersDict = workspacesModule.workspaceUsers;
                return usersDict[value]?.user.email;
            },
        },
        updated: {
            label: "Last Updated",
            property: "updated",
            visible: false,
            enabled: false,
            locked: false,
            parseFunction: (value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const timestamp = require("unix-timestamp");
                const date =  timestamp.toDate(value) as Date;
                const dateDisplayOptions: DateTimeFormatOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                };
                return date.toLocaleString("en-US", dateDisplayOptions)
            },
        },
        created: {
            label: "Created",
            property: "created",
            visible: false,
            enabled: false,
            parseFunction: (value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const timestamp = require("unix-timestamp");
                const date =  timestamp.toDate(value) as Date;
                const dateDisplayOptions: DateTimeFormatOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                };
                return date.toLocaleString("en-US", dateDisplayOptions)
            },
            locked: false,
            defaultOff: true,
        },
    };

    private actions: { [id: string]: TableAction } = {
        edit: {
            id: "edit",
            label: "Edit",
            icon: TableIcons.edit,
            active: false,
            enabled: false,
            locked: false,
            disabled: (val = this.choiceWrite) => (val ? true : false),
        },
        duplicate: {
            id: "duplicate",
            label: "Duplicate",
            icon: TableIcons.duplicate,
            active: false,
            enabled: false,
            locked: false,
            disabled: (val = this.choiceWrite) => (val ? true : false),
        },
        delete: {
            id: "delete",
            label: "Delete",
            icon: TableIcons.delete,
            active: false,
            multi: true,
            enabled: false,
            locked: false,
            disabled: (val = this.choiceWrite) => (val ? true : false),
        },
    };

    private tools: { [id: string]: TableAction } = {
        new: {
            id: "new",
            label: "New",
            icon: TableIcons.new,
            active: false,
            enabled: false,
            locked: false,
            disabled: (val = this.choiceWrite) => (val ? true : false),
        },
    };

    get choices(): Choice[] {
        return choiceModule.choices;
    }

    get choiceItems(): Choice[] {
        if (this.searchTerm && this.searchTerm.length) {
            return this.choices.filter((choice) => {
                return choice.name
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase());
            });
        } else {
            return this.choices;
        }
    }

    /* Selected Choices */
    get choiceList(): { [id: number]: Choice } {
        return choiceModule.choiceList;
    }

    get selectedChoices(): Choice[] {
        if (this.selected) {
            return this.selected.map((id) => this.choiceList[id]);
        } else {
            return [];
        }
    }

    get selectedChoice(): Choice | null {
        if (this.selectedChoices.length) {
            return this.selectedChoices[0];
        } else {
            return null;
        }
    }

    /* End of Selected Choices */

    get viewLoading(): boolean {
        return decisionsModule.dataLoading;
    }

    private createNew(): void {
        this.selected = [];
        this.isCopy = false;
        this.sideTrigger = `new-choice`;
    }

    private openChoiceEditor(choice: Choice): void {
        if (choice) {
            this.isCopy = false;
            this.openEditor(choice);
        }
    }

    private openEditor(choice: Choice): void {
        if (choice) {
            this.selected = [choice.id];
            this.editorOpen = true;
            this.sideTrigger = `edit-${choice.id}`;
        }
    }

    private duplicateChoice(choice: Choice): void {
        this.isCopy = true;
        this.openEditor(choice);
    }

    private deleteChoice(choice: Choice): void {
        if (choice) {
            this.selected = [choice.id];
            this.deleteOpen = true;
        }
    }

    private async deleteChoices(): Promise<void> {
        if (this.selectedChoices.length) {
            this.loading = true;
            await Promise.all(
                this.selectedChoices.map(async (choice) => {
                    if (choice) {
                        await choiceModule.deleteChoice(choice.id);
                    }
                })
            );
            this.deleteOpen = false;
            this.selected = [];
            this.loading = false;
        }
    }

    private toolClick(eventId: string): void {
        switch (eventId) {
            case "new":
                this.createNew();
                break;
            case "edit":
                if (this.selectedChoice) {
                    this.isCopy = false;
                    this.sideTrigger = `edit-${this.selectedChoice.id}`;
                }
                break;
            case "delete":
                this.deleteOpen = true;
                break;
            case "duplicate":
                if (this.selectedChoice) {
                    this.isCopy = true;
                    this.editorOpen = true;
                    this.sideTrigger = `duplicate-${this.selectedChoice.id}`;
                }
                break;
        }
    }

    private async selectChoice(choice: Choice): Promise<void> {
        if (choice && this.choiceValueRead) {
            this.selected = [choice.id];
            this.modalOpen = true;
            await choiceModule.getChoiceValues({
                choice_id: choice.id,
                root_only: true,
            });
        }
    }
}
