import { Component, Vue } from "vue-property-decorator";
import { HashRoutes, HashRoute } from "@/graphql/API";
import { loginRoutes } from "@/Urls";

@Component
export default class LoginRouting extends Vue {
    private readonly loginRoutes: HashRoutes = loginRoutes;

    get hash(): string | null {
        return this.$route.hash?.length ? this.$route.hash.slice(1) : null;
    }

    get hashId(): string {
        return this.hash ? this.hash : "login";
    }

    get hashRoute(): HashRoute {
        if (this.loginRoutes.hasOwnProperty(this.hashId)) {
            return this.loginRoutes[this.hashId];
        } else {
            return this.loginRoutes["login"];
        }
    }

    get componentName(): string {
        return this.hashRoute.component;
    }

    goBack(): void {
        this.changeHash(this.hashRoute.back);
    }

    changeHash(val: string | null): void {
        if (val || this.hash) {
            /* Stops duplicate route error if there is no hash
            and user is trying to go to home */
            this.$router.push({
                path: this.$route.path,
                query: this.$route.query,
                hash: val ? `#${val}` : "",
            });
        }
    }
}
