
import LoginMain from "@/components/signIn/LoginMain.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import LoginRouting from "@/components/mixins/LoginRouting";
import WelcomeView from "@/views/WelcomeView.vue";

@Component({
    components: {
        LoginMain,
        WelcomeView,
    },
})
export default class BaseView extends mixins(LoginRouting) {
    @Prop({ default: false, type: Boolean })
    appLoading!: boolean;

    @Prop({ default: false, type: Boolean })
    loadError!: boolean;

    @Prop({ default: false, type: Boolean })
    routerLayout!: boolean;

    @Prop({ type: Boolean, default: false })
    loggedIn!: boolean;

    private loading = false;
    private splitScreen = false;

    get showBackBtn(): boolean {
        return this.hashId && this.hashId != "login" ? true : false;
    }

    get showLoading(): boolean {
        return this.loading || this.appLoading;
    }

    get showSplitScreen(): boolean {
        return this.showLoading ? false : this.splitScreen;
    }

    private setLoading(val: boolean): void {
        this.loading = val;
    }

    mounted(): void {
        if (this.hash) {
            this.splitScreen = true;
        }
    }

    @Watch("loggedIn")
    onLoggedInChange(val: boolean): void {
        if (val && this.loading) {
            this.loading = false;
        }
    }
}
