var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-app-selector"},_vm._l((_vm.decisions),function(decision,index){return _c('div',{key:index},[_c('div',{staticClass:"vp-app-selector__decision"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleId(decision.id)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.openId == decision.id ? "mdi-chevron-down" : "mdi-chevron-right")+" ")])],1),_c('span',[_vm._v(_vm._s(decision.name))]),(_vm.loading[decision.id])?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":30}}):_vm._e()],1),(_vm.openId == decision.id)?_c('div',{staticClass:"vp-padding-left-3 vp-app-selector__list"},[(!_vm.loading[decision.id])?[(
                        _vm.appList[decision.id] && _vm.appList[decision.id].length
                    )?_c('div',_vm._l((_vm.appList[decision.id]),function(app,appIndex){return _c('div',{key:`app-${appIndex}`,staticClass:"vp-app-selector__app",class:{
                            'vp-app-selector__app--active': _vm.selected && _vm.selected === app.id,
                        },on:{"click":function($event){return _vm.$emit('app-toggle', {
                                app: app,
                                decision: decision,
                            })}}},[_vm._v(" "+_vm._s(app.title)+" ")])}),0):_c('div',{staticClass:"vp-app-selector__app"},[_vm._v("No Apps")])]:_vm._e()],2):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }