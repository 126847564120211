
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UsersModule from "@/store/modules/Users";
import { Invite, TableHeader, TableAction } from "@/graphql/API";
import TableManagerView from "@/components/managers/TableManagerView.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import FlashNotifications from "@/store/modules/FlashNotifications";

const userModule = getModule(UsersModule);
const flashModule = getModule(FlashNotifications);

interface InviteItem extends Invite {
    id: number;
}

@Component({
    components: {
        TableManagerView,
        VpDialog,
    },
})
export default class InviteManager extends Vue {
    @Prop({ default: () => [], type: Array })
    invites!: Invite[];

    @Prop({ default: false, type: Boolean })
    parentLoading!: boolean;

    private selected: number[] = [];
    private searchTerm = "";
    private deleteOpen = false;
    private loading = false;

    private headers: { [id: string]: TableHeader } = {
        email: {
            label: "Email",
            property: "email",
            visible: false,
            enabled: false,
            locked: false,
        },
        read: {
            label: "Read",
            property: "read",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
        write: {
            label: "Write",
            property: "write",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
        admin: {
            label: "Admin",
            property: "admin",
            visible: false,
            enabled: false,
            type: "boolean",
            locked: false,
        },
    };
    private actions: { [id: string]: TableAction } = {
        edit: {
            id: "delete",
            label: "Delete",
            icon: "mdi-delete",
            active: true,
            enabled: true,
            multi: true,
        },
    };

    private tools: { [id: string]: TableAction } = {};

    get showLoading(): boolean {
        return this.parentLoading || this.loading;
    }

    get inviteList(): InviteItem[] {
        return this.invites.map((invite, index) => {
            return {
                id: index + 1,
                ...invite,
            };
        });
    }

    get inviteItemList(): InviteItem[] {
        if (this.searchTerm && this.searchTerm.length) {
            return this.inviteList.filter((invite) => {
                if (invite.email) {
                    return invite.email
                        .toLocaleLowerCase()
                        .includes(this.searchTerm.toLocaleLowerCase());
                } else {
                    return false;
                }
            });
        } else {
            return this.inviteList;
        }
    }

    get keyedInvites(): { [id: string]: InviteItem } {
        return this.inviteList.reduce((acc, a) => {
            return {
                ...acc,
                [a.id]: a,
            };
        }, {});
    }

    get selectedInvites(): InviteItem[] {
        return this.selected.map((id) => this.keyedInvites[id]);
    }

    private toggleView(): void {
        this.$emit("toggle-users");
    }

    private toggleDelete(invite: InviteItem): void {
        if (invite) {
            this.selected = [invite.id];
            this.deleteOpen = true;
        }
    }

    private toolClick(eventId: string): void {
        switch (eventId) {
            case "delete":
                if (this.selectedInvites.length) {
                    this.deleteOpen = true;
                }
                break;
        }
    }

    private async deleteInvites(): Promise<void> {
        if (this.selectedInvites.length) {
            this.loading = true;
            const delInvites = await Promise.all(
                this.selectedInvites.map(async (invite) => {
                    if (invite && invite.email) {
                        return await userModule.deletePending(invite.email);
                    }
                })
            );
            this.$emit("invites-deleted");
            const emails: (string | null)[] = delInvites
                .map((invite) => {
                    return invite?.email ? invite.email : null;
                })
                .filter((email) => email);
            flashModule.success({
                message: `${emails.join(", ")} deleted`,
                duration: 3000,
            });
            this.loading = false;
            this.deleteOpen = false;
            this.selected = [];
        }
    }

    /* search string should already be lower case */
    private filterUser(
        search: string,
        user: {
            email?: string;
            first?: string;
            last?: string;
        }
    ): boolean {
        if (user.email && user.email.toLowerCase().includes(search)) {
            return true;
        } else if (
            user.first &&
            user.first.toLocaleLowerCase().includes(search)
        ) {
            return true;
        } else if (
            user.last &&
            user.last.toLocaleLowerCase().includes(search)
        ) {
            return true;
        } else {
            return false;
        }
    }
}
