
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Decisions from "@/store/modules/Decisions";
import { saveTab } from "@/helpers/TabHelper";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import {
    TableHeader,
    TableAction,
    Tab,
    TableTabFlags,
    App,
} from "@/graphql/API";
import TableManager from "@/components/managers/TableManager.vue";
import TableManagerTools from "@/components/managers/TableManagerTools.vue";
import TableManagerSettings from "@/components/managers/TableManagerSettings.vue";
import ViewAppCreator from "@/components/apps/ViewAppCreator.vue";
import { Urls } from "@/Urls";
import UnSavedChanges from "@/components/ui/UnSavedChanges.vue";

const decisionsModule = getModule(Decisions);

@Component({
    components: {
        ViewWrapper,
        TableManager,
        TableManagerTools,
        TableManagerSettings,
        ViewAppCreator,
        UnSavedChanges,
    },
})
export default class TableManagerView extends Vue {
    /* File name of component calling this component */
    @Prop({ required: true })
    type!: string;

    @Prop({ type: String, default: "id" })
    idField!: string;

    @Prop({ type: Boolean, default: false })
    viewLoading!: boolean;

    @Prop({ type: Boolean, default: false })
    logoOverlay!: boolean;

    @Prop({ type: Boolean, default: false })
    isEdit!: boolean;

    @Prop({ type: Boolean, default: false })
    newTab!: boolean;

    @Prop({ type: Boolean, default: false })
    embed!: boolean;

    @Prop({ type: Boolean, default: false })
    multi!: boolean;

    @Prop({ type: Boolean, default: true })
    appCreate!: boolean;

    @Prop({ type: Boolean, default: true })
    showSearch!: boolean;

    @Prop({ type: Boolean, default: false })
    closeSidebarDefault!: boolean;

    @Prop({ type: Boolean, default: false })
    modalOpen!: boolean;

    @Prop()
    tabSettings!: Tab;

    @Prop()
    app!: App;

    @Prop({ type: Boolean, default: false })
    tabCheckBoxes!: boolean;

    @Prop({ type: Boolean, default: true })
    write!: boolean;

    @Prop({ type: Boolean, default: true })
    showActions!: boolean;

    @Prop({ type: Boolean, default: true })
    hasSideBar!: boolean;

    /* Items require an ID property */
    @Prop({ default: () => [], type: Array })
    items!: [];

    @PropSync("selected")
    syncedSelected!: number[];

    @PropSync("headers")
    viewHeaders!: { [id: string]: TableHeader };

    @PropSync("actions")
    viewActions!: { [id: string]: TableAction };

    @PropSync("tools")
    viewTools!: { [id: string]: TableAction };

    @PropSync("searchTerm")
    syncedSearchTerm!: string;

    @Prop({ default: () => [], type: Array })
    tabHeaders!: string[];

    @Prop({ default: () => [], type: Array })
    tabActions!: string[];

    @Prop({ default: () => [], type: Array })
    tabTools!: string[];

    @Prop({ default: () => [], type: Array })
    tabHeadersLocks!: string[];

    @Prop({ default: () => [], type: Array })
    tabActionsLocks!: string[];

    @Prop({ default: () => [], type: Array })
    tabToolsLocks!: string[];

    @Prop({ default: "", type: String })
    modalTitle!: string;

    @PropSync("sideBar")
    syncedSideBar!: string;

    readonly URLS = Urls;

    private unSavedWarningOpen = false;

    private appCreateOpen = false;
    private loading = false;

    private checkboxes = false;

    private sideTrigger = "sidebar-close";

    get decisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get tableHeaders(): TableHeader[] {
        return Object.values(this.viewHeaders).filter((item) => item.enabled);
    }

    get tableTools(): TableAction[] {
        return Object.values(this.viewTools).filter((item) => item.enabled);
    }

    get tableActions(): TableAction[] {
        return Object.values(this.viewActions).filter((item) => item.enabled);
    }

    get columns(): TableHeader[] {
        if (this.tableHeaders) {
            return this.tableHeaders.filter((item) => item.visible);
        } else {
            return [];
        }
    }

    get isMainTool(): boolean {
        return !this.isEdit && !this.embed;
    }

    get hasModalContent(): boolean {
        return this.$slots.hasOwnProperty("modalContent");
    }

    /* App Settings */
    get headerFlags(): string[] {
        return Object.values(this.viewHeaders)
            .filter((header) => header && header.visible)
            .map((header) => header.property);
    }

    get toolFlags(): string[] {
        return Object.values(this.viewTools)
            .filter((tool) => tool && tool.active)
            .map((tool) => tool.id);
    }

    get actionFlags(): string[] {
        return Object.values(this.viewActions)
            .filter((action) => action && action.active)
            .map((action) => action.id);
    }

    get headerLocks(): string[] {
        return Object.values(this.viewHeaders)
            .filter((header) => header && header.locked)
            .map((header) => header.property);
    }

    get toolLocks(): string[] {
        return Object.values(this.viewTools)
            .filter((tool) => tool && tool.locked)
            .map((tool) => tool.id);
    }

    get actionLocks(): string[] {
        return Object.values(this.viewActions)
            .filter((action) => action && action.locked)
            .map((action) => action.id);
    }

    /* Props for detecting Change */
    get toolsChanged(): boolean {
        if (this.toolFlags.length == this.tabTools.length) {
            if (
                this.toolFlags.every((val: string) =>
                    this.tabTools.includes(val)
                ) &&
                this.tabTools.every((val: string) =>
                    this.toolFlags.includes(val)
                )
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    get actionsChanged(): boolean {
        if (this.actionFlags.length == this.tabActions.length) {
            if (
                this.actionFlags.every((val: string) =>
                    this.tabActions.includes(val)
                ) &&
                this.tabActions.every((val: string) =>
                    this.actionFlags.includes(val)
                )
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    get headersChanged(): boolean {
        if (this.headerFlags.length == this.tabHeaders.length) {
            if (
                this.headerFlags.every((val: string) =>
                    this.tabHeaders.includes(val)
                ) &&
                this.tabHeaders.every((val: string) =>
                    this.headerFlags.includes(val)
                )
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    get optionsChange(): boolean {
        return this.headersChanged || this.actionsChanged || this.toolsChanged;
    }

    /* Lock Change Detection */

    get toolLockChanged(): boolean {
        if (this.tabToolsLocks.length == this.toolLocks.length) {
            if (
                this.tabToolsLocks.every((val: string) =>
                    this.toolLocks.includes(val)
                ) &&
                this.toolLocks.every((val: string) =>
                    this.tabToolsLocks.includes(val)
                )
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    get actionLocksChanged(): boolean {
        if (this.tabActionsLocks.length == this.actionLocks.length) {
            if (
                this.tabActionsLocks.every((val: string) =>
                    this.actionLocks.includes(val)
                ) &&
                this.actionLocks.every((val: string) =>
                    this.tabActionsLocks.includes(val)
                )
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    get headerLocksChanged(): boolean {
        if (this.tabHeadersLocks.length == this.headerLocks.length) {
            if (
                this.tabHeadersLocks.every((val: string) =>
                    this.headerLocks.includes(val)
                ) &&
                this.headerLocks.every((val: string) =>
                    this.tabHeadersLocks.includes(val)
                )
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    get locksChanged(): boolean {
        return (
            this.headerLocksChanged ||
            this.toolLockChanged ||
            this.actionLocksChanged
        );
    }

    get totalChanged(): boolean {
        return (
            this.locksChanged ||
            this.optionsChange ||
            this.tabCheckBoxes != this.checkboxes
        );
    }

    /* End of Props for detecting Change */

    get editFlags(): TableTabFlags {
        return {
            edit: {
                headers: this.headerFlags,
                tools: this.toolFlags,
                actions: this.actionFlags,
            },
            locks: {
                headers: this.headerLocks,
                tools: this.toolLocks,
                actions: this.actionLocks,
            },
            checkBoxes: this.checkboxes,
        };
    }

    get currentSettings(): Tab {
        if (this.tabSettings) {
            return {
                ...this.tabSettings,
                edit_flags: JSON.stringify(this.editFlags),
            };
        } else {
            return {
                id: -1,
                decision_id: this.decisionId ? this.decisionId : -1,
                title: "Table View",
                json: "",
                edit_flags: JSON.stringify(this.editFlags),
                display_flags: "",
                filter_type: "factor",
                row_type: "choice",
                column_type: "viewpoint",
                type: this.type,
            };
        }
    }

    /* End of App Settings */

    get tabLink(): string | null {
        if (this.isEdit && this.app && this.tabSettings) {
            return `${this.URLS.APPS}/${this.app.id}/${this.tabSettings.id}`;
        } else {
            return null;
        }
    }

    /* App Saving */
    private async saveSettings(): Promise<void> {
        if (this.tabSettings && this.isEdit) {
            this.loading = true;
            await saveTab({
                tab: {
                    ...this.currentSettings,
                },
                new: false,
                factors: [],
                choices: [],
                viewpoints: [],
            });
            this.loading = false;
        } else {
            this.appCreateOpen = true;
        }
    }

    private tableClick(evt: { id: string; item: any }): void {
        if (evt.id) {
            this.$emit(evt.id, evt.item);
        }
    }

    private openSettings(): void {
        this.syncedSideBar = "settings";
        this.sideTrigger = "settings";
    }

    private async loadSettings(loadAll: boolean): Promise<void> {
        if (!this.embed || loadAll) {
            Object.keys(this.viewHeaders).map((item) => {
                Vue.set(this.viewHeaders[item], "enabled", true);
                if (!this.isEdit || loadAll) {
                    if (this.viewHeaders[item]) {
                        Vue.set(this.viewHeaders[item], "locked", false);

                        if (!this.viewHeaders[item].defaultOff) {
                            Vue.set(this.viewHeaders[item], "visible", true);
                        }
                    }
                }
            });
            Object.keys(this.viewActions).map((item) => {
                Vue.set(this.viewActions[item], "enabled", true);
                if (!this.isEdit || loadAll) {
                    Vue.set(this.viewActions[item], "active", true);
                    if (this.viewHeaders[item]) {
                        Vue.set(this.viewHeaders[item], "locked", false);
                    }
                }
            });
            Object.keys(this.viewTools).map((item) => {
                Vue.set(this.viewTools[item], "enabled", true);
                if (!this.isEdit || loadAll) {
                    Vue.set(this.viewTools[item], "active", true);
                    if (this.viewHeaders[item]) {
                        Vue.set(this.viewHeaders[item], "locked", false);
                    }
                }
            });
        }
    }

    async mounted(): Promise<void> {
        if (this.closeSidebarDefault) {
            this.onSideBarChange("sidebar-close");
        }
        this.loadSettings(this.newTab);
        this.onCheckBoxesChange(this.tabCheckBoxes);
    }

    /* Load Headers/Actions */
    private activateHeaders(headers: string[], val: boolean) {
        headers.map((label) => {
            if (this.embed) {
                Vue.set(this.viewHeaders[label], "enabled", val);
            }
            Vue.set(this.viewHeaders[label], "visible", val);
        });
    }

    private activateActions(actions: string[], val: boolean) {
        actions.map((label) => {
            if (this.embed) {
                Vue.set(this.viewActions[label], "enabled", val);
            }
            Vue.set(this.viewActions[label], "active", val);
        });
    }

    private activateTools(tools: string[], val: boolean) {
        tools.map((label) => {
            if (this.embed) {
                Vue.set(this.viewTools[label], "enabled", val);
            }
            Vue.set(this.viewTools[label], "active", val);
        });
    }

    /* Lock Functions */
    private lockHeaders(headers: string[], val: boolean) {
        headers.map((label) => {
            Vue.set(this.viewHeaders[label], "locked", val);
        });
    }

    private lockActions(actions: string[], val: boolean) {
        actions.map((label) => {
            Vue.set(this.viewActions[label], "locked", val);
        });
    }

    private lockTools(tools: string[], val: boolean) {
        tools.map((label) => {
            Vue.set(this.viewTools[label], "locked", val);
        });
    }
    /* End of Load Headers/Actions */

    private openSaveWarning(): void {
        this.unSavedWarningOpen = true;
    }

    private async dialogSave(): Promise<void> {
        this.unSavedWarningOpen = false;
        await this.saveSettings();
        if (this.tabLink) {
            window.open(this.tabLink, "_self");
        }
    }

    @Watch("tabHeaders")
    onTabHeadersChange(newVal: string[], oldVal: string[] = []): void {
        if (!this.isMainTool) {
            this.activateHeaders(
                newVal.filter((x) => !oldVal.includes(x)),
                true
            );
            this.activateHeaders(
                oldVal.filter((x) => !newVal.includes(x)),
                false
            );
        }
    }

    @Watch("tabActions")
    onTabActionsChange(newVal: string[], oldVal: string[] = []): void {
        if (!this.isMainTool) {
            this.activateActions(
                newVal.filter((x) => !oldVal.includes(x)),
                true
            );
            this.activateActions(
                oldVal.filter((x) => !newVal.includes(x)),
                false
            );
        }
    }

    @Watch("tabTools")
    onTabToolsChange(newVal: string[], oldVal: string[] = []): void {
        if (!this.isMainTool) {
            this.activateTools(
                newVal.filter((x) => !oldVal.includes(x)),
                true
            );
            this.activateTools(
                oldVal.filter((x) => !newVal.includes(x)),
                false
            );
        }
    }

    @Watch("tabHeadersLocks")
    onTabHeaderLocksChange(newVal: string[], oldVal: string[] = []): void {
        this.lockHeaders(
            newVal.filter((x) => !oldVal.includes(x)),
            true
        );
        this.lockHeaders(
            oldVal.filter((x) => !newVal.includes(x)),
            false
        );
    }

    @Watch("tabActionsLocks")
    onTabActionLocksChange(newVal: string[], oldVal: string[] = []): void {
        this.lockActions(
            newVal.filter((x) => !oldVal.includes(x)),
            true
        );
        this.lockActions(
            oldVal.filter((x) => !newVal.includes(x)),
            false
        );
    }

    @Watch("tabToolsLocks")
    onTabToolLocksChange(newVal: string[], oldVal: string[] = []): void {
        this.lockTools(
            newVal.filter((x) => !oldVal.includes(x)),
            true
        );
        this.lockTools(
            oldVal.filter((x) => !newVal.includes(x)),
            false
        );
    }

    /* End of Table Loading */
    @Watch("syncedSideBar")
    onSideBarChange(val: string): void {
        this.sideTrigger = val;
    }

    @Watch("newTab")
    onNewTab(val: boolean): void {
        if (val) {
            this.loadSettings(true);
        }
    }

    @Watch("tabCheckBoxes")
    onCheckBoxesChange(val: boolean): void {
        this.checkboxes = val;
    }
}
