
import { Vue, Component } from "vue-property-decorator";
import { Rules } from "@/helpers/FormRules";
import { Auth } from "aws-amplify";
@Component({
    components: {},
})
export default class Reset extends Vue {
    private email = "";
    private loading = false;
    private isFormValid = false;
    private errMsg = "";

    private rules = Rules;

    private async sendReset(): Promise<void> {
        if (this.email.length) {
            this.loading = true;
            try {
                await Auth.forgotPassword(this.email);
                this.loading = false;
                this.$emit("email-redirect", {
                    email: this.email,
                    hash: "resetform",
                });
            } catch (err: any) {
                if (err.name === "UserNotFoundException") {
                    this.setErrorMsg("User does not exist");
                } else {
                    this.setErrorMsg(err);
                }
                this.loading = false;
            }
        }
    }

    private async haveCode(): Promise<void> {
        this.$emit("email-redirect", {
            email: this.email,
            hash: "resetform",
        });
    }

    private setErrorMsg(error: any): void {
        this.errMsg = error;
        setTimeout(() => (this.errMsg = ""), 3000);
    }
}
