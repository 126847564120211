
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Hub } from "@aws-amplify/core";
import { API, Auth, graphqlOperation } from "aws-amplify";
import Decisions from "./store/modules/Decisions";
import { getModule } from "vuex-module-decorators";
import Factors from "./store/modules/Factors";
import { Decision, Value, Workspace, PageSettings } from "./graphql/API";
import Observable from "zen-observable-ts";
import {
    onChange,
    onCompanyChange,
    onWorkspaceChange,
} from "./graphql/subscriptions";
import { Urls } from "./Urls";
import Choices from "./store/modules/Choices";
import Viewpoints from "./store/modules/Viewpoints";
import { OnChange, OnCompanyChange, OnWorkspaceChange } from "./graphql/custom";
import Scoring from "./store/modules/Scoring";
import { GraphQLResult } from "@aws-amplify/api";
import Workspaces from "./store/modules/Workspaces";
import Domains from "./store/modules/Domains";
import {
    loadWorkspaceStyles,
    resetWorkspaceStyles,
} from "@/helpers/UiColourHelper";
import Apps from "./store/modules/Apps";
import Users from "./store/modules/Users";
import Ui from "@/store/modules/Ui";
import { ConnectionState, CONNECTION_STATE_CHANGE } from "@aws-amplify/pubsub";
import LoggedOutView from "./views/LoggedOutView.vue";
import Comments from "./store/modules/Comments";
import BaseView from "@/views/BaseView.vue";

const factorModule = getModule(Factors);
const viewpointModule = getModule(Viewpoints);
const choicesModule = getModule(Choices);
const scoringModule = getModule(Scoring);
const domainsModule = getModule(Domains);
const decisionsModule = getModule(Decisions);
const workspaceModule = getModule(Workspaces);
const appsModule = getModule(Apps);
const uiModule = getModule(Ui);
const userModule = getModule(Users);
const commentModule = getModule(Comments);
@Component({
    components: {
        LoggedOutView,
        BaseView,
    },
})
export default class App extends Vue {
    private authState = "signedOut";

    private id = "";
    private decisionSubscription: any = null;
    private globalDecisionSubscription: any = null;
    private workspaceSubscription: any = null;
    private companySubscription: any = null;

    private decisionsLoading = false;
    private appsLoading = false;
    private workspacesLoading = false;
    private platformLoading = false;

    private readonly urls = Urls;
    private priorConnectionState: ConnectionState | null = null;

    private loadError = false;

    private workspaceColorLoading = true;

    mounted(): void {
        document.title = process.env.VUE_APP_TITLE
            ? process.env.VUE_APP_TITLE
            : "Viewpoint AI";
        this.onRouteChange();
    }

    beforeCreate(): void {
        /* Calls when user goes through login process */
        Hub.listen("auth", (data) => {
            switch (data.payload.event) {
                case "signIn":
                    Auth.currentAuthenticatedUser().then((user: any) =>
                        getModule(Users).selectUser(user.attributes)
                    );
                    this.loadPlatform(true);

                    break;
                case "signOut":
                    this.authState = "signedOut";
                    // signout actions, redirect to '/' etc
                    break;
                case "customOAuthState":
                    // other changes
                    break;
            }
        });
        Hub.listen("api", (data: any) => {
            const { payload } = data;
            if (payload.event === CONNECTION_STATE_CHANGE) {
                if (
                    this.priorConnectionState ===
                        ConnectionState.ConnectedPendingNetwork ||
                    this.priorConnectionState ===
                        ConnectionState.Disconnected ||
                    (this.priorConnectionState ===
                        ConnectionState.ConnectionDisrupted &&
                        payload.data.connectionState ===
                            ConnectionState.Connected)
                ) {
                    // Refetch data here on reconnect. Subscription seems to be doing this automatically for now.
                }
                this.priorConnectionState = payload.data.connectionState;
            }
        });

        /* Calls when user is still logged in and opens webpage */
        Auth.currentAuthenticatedUser()
            .then((user: any) => {
                getModule(Users).selectUser(user.attributes);
                this.loadPlatform(false);
            })
            .catch(() => {
                this.authState = "signedOut";
            });
    }

    private login() {
        console.log(Auth.Credentials);
        Auth.federatedSignIn();
    }

    get isInvitation(): boolean {
        return this.$route.name == "InvitationView";
    }

    get baseLayout(): boolean {
        if (this.$route.meta && this.$route.meta.baseUI) {
            return true;
        }
        return false;
    }

    get selectedDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get selectedDecision(): Decision | null {
        return decisionsModule.selectedDecision;
    }

    get selectedWorkspaceId(): number | null {
        return getModule(Workspaces).selectedWorkspaceId;
    }

    get selectedWorkspace(): Workspace | null {
        return getModule(Workspaces).selectedWorkspace;
    }

    get appSettings(): PageSettings {
        return getModule(Decisions).currentSelections;
    }

    get isLandingPage(): boolean {
        console.log(this.$router.currentRoute.path);
        return this.$router.currentRoute.path === "/";
    }

    @Watch("selectedWorkspace", { deep: true })
    private async onWatchSelectedWorkspace() {
        this.workspaceColorLoading = true;
        if (this.selectedWorkspace) {
            await loadWorkspaceStyles(this.selectedWorkspace.json);
        } else {
            await resetWorkspaceStyles();
        }
        this.workspaceColorLoading = false;
    }

    //Watch for exiting/removing selected decision from state when workspace changed
    @Watch("selectedWorkspaceId")
    private async onWatchSelectedWorkspaceId() {
        if (this.selectedWorkspaceId != null) {
            workspaceModule.clearActiveWorkspace();
            scoringModule.fetchScoreRules(this.selectedWorkspaceId);
            await this.unSubWorkspaceSub();
            // this.initWorkspaceSub(this.selectedWorkspaceId);
            if (userModule.currentUserId) {
                decisionsModule.changeSetting({
                    id: "workspace_id",
                    value: this.selectedWorkspaceId,
                });
                decisionsModule.changeSetting({
                    id: "user_id",
                    value: userModule.currentUserId,
                });
                await this.loadPermissions(this.selectedWorkspaceId);
            }

            workspaceModule.updateLoading(true);
            this.initClasses();
            await this.fetchDecisions();
            await workspaceModule.getWorkspaceUsers(this.selectedWorkspaceId);
            workspaceModule.updateLoading(false);
            if (
                this.selectedDecisionId &&
                !(this.selectedDecisionId in decisionsModule.decisions)
            ) {
                decisionsModule.selectDecision(null);
                this.clear();
            }
        } else {
            decisionsModule.clearDecisions();
            getModule(Scoring).clearDefaultScores();
            this.clear();
        }
    }

    @Watch("appSettings", { immediate: true, deep: true })
    private onAppSettingsChange(): void {
        if (this.appSettings.workspace_id && this.appSettings.decision_id) {
            localStorage.setItem(
                "viewpoint-settings",
                JSON.stringify(this.appSettings)
            );
        }
    }

    private async loadPermissions(workspaceId: number): Promise<void> {
        userModule.updateLoading(true);
        await userModule.fetchMyPermissions(workspaceId);
        userModule.updateLoading(false);
    }

    private async fetchApps(): Promise<void> {
        if (this.selectedDecisionId) {
            try {
                appsModule.updateLoading(true);
                await appsModule.getApps(this.selectedDecisionId);
            } catch (e) {
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
            }
            appsModule.updateLoading(false);
        }
    }

    private async fetchDecisions(): Promise<void> {
        if (this.selectedWorkspaceId) {
            decisionsModule.updateDecisionsLoading(true);
            try {
                this.decisionsLoading = true;
                await decisionsModule.fetchWorkspaceDecisions(
                    this.selectedWorkspaceId
                );
                this.decisionsLoading = false;
            } catch (e) {
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
            }
            decisionsModule.updateDecisionsLoading(false);
        }
    }

    private clear() {
        choicesModule.clear();
        scoringModule.clear();
        factorModule.clearFactorState();
        appsModule.clearApps();
        this.unsubOnChangeSub();
    }

    @Watch("selectedDecisionId")
    private async onWatchSelectedDecision() {
        appsModule.selectApp(null);
        getModule(Decisions).getUserDecisionCount();
        if (this.selectedDecisionId && this.selectedDecisionId != null) {
            decisionsModule.changeSetting({
                id: "decision_id",
                value: this.selectedDecisionId,
            });
            decisionsModule.updateLoading(true);
            this.clear();

            factorModule.getEnums(this.selectedDecisionId);
            this.initDomains();

            this.initDecisionSub(this.selectedDecisionId);
            factorModule.updateLoading(true);
            await factorModule.fetchRootFactors(this.selectedDecisionId);
            factorModule.updateLoading(false);
            commentModule.clear();
            await commentModule.fetchThreads({
                decision_id: this.selectedDecisionId,
                include_resolved: false,
            });
            await Promise.all([
                choicesModule.fetchChoices(this.selectedDecisionId),
                viewpointModule.fetchViewpoints(this.selectedDecisionId),
                this.fetchApps(),
            ]);
            decisionsModule.updateLoading(false);
        }
    }

    private async initDomains() {
        domainsModule.updateLoading(true);
        await domainsModule.fetchUnits(-1);
        domainsModule.updateLoading(false);
        // domainsModule.fetchAllUnitConvs();
    }

    private async initClasses(): Promise<void> {
        if (this.selectedWorkspaceId) {
            const workspace_id = this.selectedWorkspaceId;
            // const scoringModule = getModule(Scoring);
            scoringModule.updateLoading(true);
            await scoringModule.fetchScoreClasses(workspace_id);
            scoringModule.updateLoading(false);
            scoringModule.fetchClassClusters(workspace_id);
        }
    }

    private initDecisionSub(decision_id: number) {
        this.decisionSubscription = (
            API.graphql({
                query: onChange,
                variables: {
                    decision_id,
                },
            }) as Observable<unknown>
        ).subscribe({
            next: (pay: {
                provider: unknown;
                value: GraphQLResult<OnChange>;
            }) => {
                console.log("SUB", pay.provider, pay.value);
                const changes = pay.value.data?.onChange;
                const mutation = changes?.mutation;
                if (changes != null && mutation != null) {
                    //Check if row moved
                    if (mutation.startsWith("moveRowL")) {
                        const values = changes.values;
                        const old_row_id = changes.old_row_id;
                        console.log(values);

                        if (values != null && old_row_id != null) {
                            const tempValues: Value[] = [];
                            values.map((val: Value) => {
                                console.log(old_row_id, val.row_id);
                                let tempVal = { ...val };
                                tempVal.row_id = old_row_id;
                                tempValues.push(tempVal);
                            });

                            choicesModule.removeValues(tempValues);
                            choicesModule.addValues(values);
                            scoringModule.moveRowLocalScores({
                                values: values,
                                old_row_id: old_row_id,
                            });
                        }
                    } else if (mutation.includes("delete")) {
                        //Delete factor values
                        const factors = changes.factors;
                        if (factors)
                            factorModule.removeLocalFactor({
                                factors: factors,
                                mutation: mutation,
                            });
                        const viewpoints = changes.viewpoints;
                        if (viewpoints) {
                            viewpointModule.removeViewpoints(viewpoints);
                        }
                        const rules = changes.rules;
                        if (rules) {
                            scoringModule.removeRules(rules);
                        }

                        // const rule_ranges = changes.rule_ranges;
                        // scoringModule.removeRuleRanges({
                        //     ranges: rule_ranges,
                        //     mutation: mutation,
                        // });
                        // const rule_matches = changes.rule_matches;
                        // scoringModule.removeRuleMatches({
                        //     matches: rule_matches,
                        //     mutation: mutation,
                        // });

                        const choices = changes.choices;
                        if (choices) {
                            choicesModule.deleteLocalChoices(choices);
                        }

                        const values = changes.values;
                        if (values) {
                            choicesModule.removeValues(values);
                        }
                        // Add tabs and apps
                        const tabs = changes.tabs;
                        appsModule.removeLocalTabs(tabs);
                        const appTabs = changes.app_tabs;
                        appsModule.removeLocalAppTabs(appTabs);
                        // Add tabFactors,viewpoint,choices
                        const tabFactors = changes.tab_factors;
                        appsModule.removeLocalTabFactors(tabFactors);
                        const tabChoices = changes.tab_choices;
                        appsModule.removeLocalTabChoices(tabChoices);
                        const tabViewpoints = changes.tab_viewpoints;
                        appsModule.removeLocalTabViewpoints(tabViewpoints);
                    } else if (
                        mutation.toLowerCase().includes("create") ||
                        mutation.toLowerCase().includes("update")
                    ) {
                        //Add/update factor values
                        const factors = changes.factors;
                        if (factors) {
                            factorModule.createUpdateFactors({
                                factors: factors,
                                mutation: mutation,
                            });
                        }
                        //Add/update factor values
                        const choices = changes.choices;
                        if (choices)
                            choicesModule.createEditChoice({
                                choice: choices[0],
                                mutation: mutation,
                            });

                        //Add/update choice values
                        const values = changes?.values;
                        choicesModule.addValues(values);

                        //Add/update viewpoints
                        const viewpoints = changes.viewpoints;
                        if (viewpoints)
                            viewpointModule.addEditViewpoint({
                                viewpoint: viewpoints[0],
                                mutation: mutation,
                            });

                        //Add/update score values
                        const scores = changes.scores;
                        scoringModule.addUpdateScores(scores);

                        //Add/update rules
                        const rules = changes.rules;
                        scoringModule.addUpdateRule(rules);

                        // //Add/update rule ranges
                        // const rule_ranges = changes.rule_ranges;
                        // scoringModule.addUpdateRuleRanges({
                        //     ranges: rule_ranges,
                        //     mutation: mutation,
                        // });

                        // //Add/update rule matches
                        // const rule_matches = changes.rule_matches;
                        // scoringModule.addUpdateRuleMatches({
                        //     matches: rule_matches,
                        //     mutation: mutation,
                        // });

                        //Add/update viewpointMappings
                        const viewpointMappings = changes.viewpoint_mappings;
                        viewpointModule.addUpdateViewpointMappings(
                            viewpointMappings
                        );

                        //Add/update classMappings
                        // const classMappings = changes.class_mappings;
                        // scoringModule.addUpdateClassMapping({
                        //     classMappings: classMappings,
                        // });
                        // Add tabs and apps
                        const apps = changes.apps;
                        appsModule.addApps(apps);
                        const tabs = changes.tabs;
                        appsModule.addTabs(tabs);
                        const appTabs = changes.app_tabs;
                        appsModule.addAppTabs(appTabs);
                        // Add tabFactors,viewpoint,choices
                        const tabFactors = changes.tab_factors;
                        appsModule.addTabFactors(tabFactors);
                        const tabChoices = changes.tab_choices;
                        appsModule.addTabChoices(tabChoices);
                        const tabViewpoints = changes.tab_viewpoints;
                        appsModule.addTabViewpoints(tabViewpoints);
                    }
                }
                // console.log(changes);
            },
            error: (error: any) => {
                console.log(JSON.stringify(error));
            },
        });
    }

    private initGlobalDecisionSub(decision_id: number) {
        this.globalDecisionSubscription = (
            API.graphql({
                query: onChange,
                variables: {
                    decision_id,
                },
            }) as Observable<unknown>
        ).subscribe({
            next: (pay: {
                provider: unknown;
                value: GraphQLResult<OnChange>;
            }) => {
                console.log("SUB", pay.provider, pay.value);
                const changes = pay.value.data?.onChange;
                const mutation = changes?.mutation;
                if (changes != null && mutation != null) {
                    //Check if row moved
                    if (mutation.startsWith("moveRowL")) {
                        const values = changes.values;
                        const old_row_id = changes.old_row_id;
                        console.log(values);

                        if (values != null && old_row_id != null) {
                            const tempValues: Value[] = [];
                            values.map((val: Value) => {
                                console.log(old_row_id, val.row_id);
                                let tempVal = { ...val };
                                tempVal.row_id = old_row_id;
                                tempValues.push(tempVal);
                            });

                            choicesModule.removeValues(tempValues);
                            choicesModule.addValues(values);
                            scoringModule.moveRowLocalScores({
                                values: values,
                                old_row_id: old_row_id,
                            });
                        }
                    } else if (mutation.includes("delete")) {
                        //Delete factor values
                        const factors = changes.factors;
                        if (factors)
                            factorModule.removeLocalFactor({
                                factors: factors,
                                mutation: mutation,
                            });
                        const viewpoints = changes.viewpoints;
                        if (viewpoints) {
                            viewpointModule.removeViewpoints(viewpoints);
                        }
                        const rules = changes.rules;
                        if (rules) {
                            scoringModule.removeRules(rules);
                        }

                        // const rule_ranges = changes.rule_ranges;
                        // scoringModule.removeRuleRanges({
                        //     ranges: rule_ranges,
                        //     mutation: mutation,
                        // });
                        // const rule_matches = changes.rule_matches;
                        // scoringModule.removeRuleMatches({
                        //     matches: rule_matches,
                        //     mutation: mutation,
                        // });

                        const choices = changes.choices;
                        if (choices) {
                            choicesModule.deleteLocalChoices(choices);
                        }

                        const values = changes.values;
                        if (values) {
                            choicesModule.removeValues(values);
                        }
                        // Add tabs and apps
                        const tabs = changes.tabs;
                        appsModule.removeLocalTabs(tabs);
                        const appTabs = changes.app_tabs;
                        appsModule.removeLocalAppTabs(appTabs);
                        // Add tabFactors,viewpoint,choices
                        const tabFactors = changes.tab_factors;
                        appsModule.removeLocalTabFactors(tabFactors);
                        const tabChoices = changes.tab_choices;
                        appsModule.removeLocalTabChoices(tabChoices);
                        const tabViewpoints = changes.tab_viewpoints;
                        appsModule.removeLocalTabViewpoints(tabViewpoints);
                    } else if (
                        mutation.includes("create") ||
                        mutation.includes("update")
                    ) {
                        //Add/update factor values
                        const factors = changes.factors;
                        if (factors) {
                            factorModule.createUpdateFactors({
                                factors: factors,
                                mutation: mutation,
                            });
                        }
                        //Add/update factor values
                        const choices = changes.choices;
                        if (choices)
                            choicesModule.createEditChoice({
                                choice: choices[0],
                                mutation: mutation,
                            });

                        //Add/update choice values
                        const values = changes?.values;
                        choicesModule.addValues(values);

                        //Add/update viewpoints
                        const viewpoints = changes.viewpoints;
                        if (viewpoints)
                            viewpointModule.addEditViewpoint({
                                viewpoint: viewpoints[0],
                                mutation: mutation,
                            });

                        //Add/update score values
                        const scores = changes.scores;
                        scoringModule.addUpdateScores(scores);

                        //Add/update rules
                        const rules = changes.rules;
                        scoringModule.addUpdateRule(rules);

                        //Add/update rule ranges
                        // const rule_ranges = changes.rule_ranges;
                        // scoringModule.addUpdateRuleRanges({
                        //     ranges: rule_ranges,
                        //     mutation: mutation,
                        // });

                        // //Add/update rule matches
                        // const rule_matches = changes.rule_matches;
                        // scoringModule.addUpdateRuleMatches({
                        //     matches: rule_matches,
                        //     mutation: mutation,
                        // });

                        //Add/update viewpointMappings
                        const viewpointMappings = changes.viewpoint_mappings;
                        viewpointModule.addUpdateViewpointMappings(
                            viewpointMappings
                        );
                        //Add/update classMappings
                        // const classMappings = changes.class_mappings;
                        // scoringModule.addUpdateClassMapping({
                        //     classMappings: classMappings,
                        // });
                        // Add tabs and apps
                        const tabs = changes.tabs;
                        appsModule.addTabs(tabs);
                        const appTabs = changes.app_tabs;
                        appsModule.addAppTabs(appTabs);
                        // Add tabFactors,viewpoint,choices
                        const tabFactors = changes.tab_factors;
                        appsModule.addTabFactors(tabFactors);
                        const tabChoices = changes.tab_choices;
                        appsModule.addTabChoices(tabChoices);
                        const tabViewpoints = changes.tab_viewpoints;
                        appsModule.addTabViewpoints(tabViewpoints);
                    }
                }
                console.log(changes);
            },
            error: (error: any) => {
                console.log(JSON.stringify(error));
            },
        });
    }

    private initCompanySub(company_id?: number) {
        const tempCompanyId = company_id ?? -1;
        this.companySubscription = (
            API.graphql({
                query: onCompanyChange,
                variables: {
                    company_id: tempCompanyId,
                },
            }) as Observable<unknown>
        ).subscribe({
            next: (pay: {
                provider: unknown;
                value: GraphQLResult<OnCompanyChange>;
            }) => {
                console.log("SUB", pay.provider, pay.value);
                const changes = pay.value.data?.onCompanyChange;
                const mutation = changes?.mutation;
                if (changes != null && mutation != null) {
                    if (mutation.includes("delete")) {
                        const workspaces = changes.workspaces;
                        workspaceModule.removeWorkspaces(workspaces);

                        const apps = changes.apps;
                        appsModule.addApps(apps);
                    } else if (
                        mutation.includes("create") ||
                        mutation.includes("update")
                    ) {
                        const workspaces = changes.workspaces;
                        workspaceModule.addWorkspaces(workspaces);

                        const apps = changes.apps;
                        appsModule.addApps(apps);
                    }
                }
                console.log(changes);
            },
            error: (error: any) => {
                console.log(JSON.stringify(error));
            },
        });
    }

    private initWorkspaceSub(workspace_id?: number) {
        this.workspaceSubscription = (
            API.graphql({
                query: onWorkspaceChange,
                variables: {
                    workspace_id: workspace_id,
                },
            }) as Observable<unknown>
        ).subscribe({
            next: (pay: {
                provider: unknown;
                value: GraphQLResult<OnWorkspaceChange>;
            }) => {
                console.log("SUB", pay.provider, pay.value);
                const changes = pay.value.data?.onWorkspaceChange;
                const mutation = changes?.mutation;
                if (changes != null && mutation != null) {
                    if (mutation.includes("delete")) {
                        const decisions = changes.decisions;
                        decisionsModule.removeDecisions(decisions);
                        const apps = changes.apps;
                        appsModule.removeLocalApps(apps);
                    } else if (
                        mutation.includes("create") ||
                        mutation.includes("update")
                    ) {
                        const decisions = changes.decisions;
                        decisionsModule.addDecisions(decisions);
                        const apps = changes.apps;
                        appsModule.addApps(apps);

                        //Add update classes
                        const classes = changes.classes;
                        scoringModule.addUpdateClass({ classes: classes });
                        //Add/update classSets
                        const classSets = changes.class_sets;
                        scoringModule.addUpdateClassSet({
                            classSet: classSets,
                        });
                    }
                }
                console.log(changes);
            },
            error: (error: any) => {
                console.log(JSON.stringify(error));
            },
        });
    }

    private async loadPlatform(redirect: boolean): Promise<void> {
        this.platformLoading = true;
        this.authState = "signedIn";

        if (
            this.$route.query.next &&
            typeof this.$route.query.next == "string"
        ) {
            await this.$router.push(this.$route.query.next);
        } else if (redirect) {
            /*
            Don't redirect to home
            if (!this.$route.meta || !this.$route.meta.blockLoginRedirect) {
                // Check if user is at home
                this.$router.push(this.urls.HOME);
            }
            */
        }

        try {
            this.workspacesLoading = true;
            getModule(Workspaces).updateLoading(true);
            const list = await getModule(Workspaces).fetchWorkspaces();
            getModule(Workspaces).updateLoading(false);
            this.workspacesLoading = false;
            if (list.length > 0) {
                await this.loadSettings();
            }
            this.platformLoading = false;
        } catch (e) {
            console.log('Fetch Workspaces Error');
            console.log("%cError:", "color: red; font-weight: bold;");
            console.log(e);
            this.loadError = true;
        }
    }

    private unsubOnChangeSub() {
        if (this.decisionSubscription != null) {
            this.decisionSubscription.unsubscribe();
        }
    }

    private unSubCompanySubcription() {
        if (this.companySubscription != null) {
            this.companySubscription.unsubscribe();
        }
    }

    private async unSubWorkspaceSub() {
        if (this.workspaceSubscription != null) {
            this.workspaceSubscription.unsubscribe();
        }
    }

    private async loadSettings(): Promise<boolean> {
        if (
            this.$route.path != Urls.APPS &&
            !(this.$route.query.decId || this.$route.query.workId) &&
            !(this.$route.name == "TabEditorView")
        ) {
            const settings = localStorage.getItem("viewpoint-settings");
            if (settings) {
                const selectedSettings = JSON.parse(settings);
                if (
                    selectedSettings.user_id &&
                    userModule.currentUserId &&
                    selectedSettings.user_id == userModule.currentUserId
                ) {
                    if (!this.$route.query.w) {
                        if (selectedSettings.workspace_id) {
                            workspaceModule.selectWorkspace(
                                selectedSettings.workspace_id
                            );
                            await this.loadPermissions(
                                selectedSettings.workspace_id
                            );
                            if (!this.$route.query.f) {
                                if (selectedSettings.decision_id) {
                                    decisionsModule.selectDecision(
                                        selectedSettings.decision_id
                                    );
                                }
                            }
                        }
                    }
                }
            }
        } else if (this.$route.name == "TabEditorView") {
            /* Tab Editor is open */
            if (
                this.$route.params &&
                this.$route.params.appId &&
                this.$route.params.tabId
            ) {
                /* Fetch App and get workspace from it */
                await this.loadAppWorkspace(parseInt(this.$route.params.appId));
            }
        }
        /* Returns a value so the async will wait for it in loadPlatform() */
        return true;
    }

    private async loadAppWorkspace(appId: number): Promise<void> {
        const app = await appsModule.getApp(appId);
        if (app) {
            console.log("App:");
            console.log(app);
            const decision = await decisionsModule.fetchDecision(
                app.decision_id
            );
            if (decision) {
                await Promise.all([
                    decisionsModule.selectDecision(decision.id),
                    workspaceModule.selectWorkspace(decision.workspace_id),
                ]);
            }
        }
    }

    @Watch("$route", { immediate: true, deep: true })
    onRouteChange(): void {
        uiModule.changeBreadCrumb(null);
    }
}
