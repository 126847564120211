
import { Component, Prop, Watch } from "vue-property-decorator";
import { User } from "@/graphql/API";
import { Auth } from "aws-amplify";
import LoginMain from "@/components/signIn/LoginMain.vue";
import { mixins } from "vue-class-component";
import LoginRouting from "@/components/mixins/LoginRouting";
import ConfirmInvite from "@/components/Invite/ConfirmInvite.vue";
import { getModule } from "vuex-module-decorators";
import Users from "@/store/modules/Users";
import WorkspaceInvite from "@/components/Invite/WorkspaceInvite.vue";
import WelcomeView from "@/views/WelcomeView.vue";

const usersModule = getModule(Users);

@Component({
    components: {
        LoginMain,
        ConfirmInvite,
        WorkspaceInvite,
        WelcomeView,
    },
})
export default class InviteView extends mixins(LoginRouting) {
    @Prop()
    code!: string;

    @Prop({ default: false, type: Boolean })
    loggedIn!: boolean;

    @Prop()
    workId!: number;

    @Prop()
    app!: string;

    @Prop({ default: false, type: Boolean })
    loadError!: boolean;

    private loading = false;
    private splitScreen = false;

    get currentUser(): User | null {
        return usersModule.currentUser;
    }

    get showSplitScreen(): boolean {
        return this.loading ? false : this.splitScreen;
    }

    get showBackBtn(): boolean {
        return this.hashId && this.hashId != "login" ? true : false;
    }

    get appId(): number | null {
        if (this.app) {
            return parseInt(this.app);
        }
        return null;
    }

    get header(): string {
        return `You've been invited to ${
            this.appId ? "an App" : "a Workspace"
        }`;
    }

    @Watch("loggedIn")
    onLoggedInChange(val: boolean): void {
        if (val && this.loading) {
            this.loading = false;
        }
    }
}
