
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import FlashNotifications from "@/store/modules/FlashNotifications";
import PasswordInput from "@/components/ui/PasswordInput.vue";
import { Auth } from "aws-amplify";
const flashModule = getModule(FlashNotifications);

@Component({
    components: {
        PasswordInput,
    },
})
export default class ResetForm extends Vue {
    @Prop()
    userEmail!: string;

    private email = "";
    private resetCode = "";
    private password = "";
    private confirmPassword = "";
    private errMsg = "";

    private isFormValid = false;
    private loading = false;
    private showCheck = false;
    private passwordReset = false;

    private updatePassword(value: string): void {
        this.password = value;
    }

    private updateConfirmPassword(value: string): void {
        this.confirmPassword = value;
    }

    private reset(): void {
        this.email = "";
        this.resetCode = "";
        this.password = "";
        this.passwordReset = false;
    }

    private async confirmReset(): Promise<void> {
        this.loading = true;
        try {
            await Auth.forgotPasswordSubmit(
                this.email.trim(),
                this.resetCode.trim(),
                this.password
            );
            flashModule.success({
                message: "Password Reset",
                duration: 5000,
            });
            this.passwordReset = true;
        } catch (err) {
            this.setErrorMsg(err);
        }

        this.loading = false;
    }

    private goLogin(): void {
        this.reset();
        this.$emit("login-link", "login");
    }

    private async sendReset(): Promise<void> {
        this.loading = true;
        try {
            await Auth.forgotPassword(this.email);
            this.showCheck = true;
            setTimeout(() => {
                this.showCheck = false;
            }, 5000);
        } catch (err: any) {
            if (err.name === "UserNotFoundException") {
                this.setErrorMsg("User does not exist");
            } else {
                this.setErrorMsg(err);
            }
        }

        this.loading = false;
    }

    private setErrorMsg(error: any): void {
        this.errMsg = error;
        setTimeout(() => (this.errMsg = ""), 3000);
    }

    mounted(): void {
        this.onEmailChange(this.userEmail);
    }

    @Watch("userEmail")
    onEmailChange(val: string): void {
        if (val) {
            this.email = val;
        }
    }
}
