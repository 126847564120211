
import { Vue, Component, Prop } from "vue-property-decorator";
import { Auth } from "aws-amplify";
import { Urls } from "@/Urls";
import { User } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import Users from "@/store/modules/Users";
import Workspaces from "@/store/modules/Workspaces";

const usersModule = getModule(Users);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {},
})
export default class ConfirmInvite extends Vue {
    @Prop()
    code!: string;

    @Prop()
    workId!: number;

    @Prop()
    user!: User;

    @Prop()
    appId!: number | null;

    private loading = false;
    private showError = false;

    private readonly URLS = Urls;

    get userLabel(): string {
        if (this.user) {
            if (this.user.first) {
                return this.user.last
                    ? `${this.user.first} ${this.user.last}`
                    : this.user.first;
            } else {
                return this.user.email ? this.user.email : "User";
            }
        } else {
            return "User not found";
        }
    }

    private async confirmInvite(): Promise<void> {
        if (this.code) {
            this.$emit("loading-start");
            try {
                const res = await usersModule.redeemPermission(this.code);
                if (res) {
                    if (this.appId) {
                        this.$router.push({
                            path: `${Urls.APPS}/${this.appId}`,
                        });
                    } else {
                        await workspaceModule.fetchWorkspaces();
                        this.$router.push({
                            path: this.$route.path,
                            query: {
                                ...this.$route.query,
                                p_code: "",
                            },
                        });
                    }
                } else {
                    this.showError = true;
                }
            } catch (e) {
                this.showError = true;
            }
            this.$emit("loading-end");
        } else {
            console.log("No Code:");
        }
    }

    private async logout(): Promise<void> {
        this.$emit("loading-start");
        this.showError = false;
        this.loading = true;
        await Auth.signOut();
        this.loading = false;
        this.$emit("loading-end");
    }
}
