
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
    InviteUserLInput,
    PermissionInviteInput,
    UpdatePermissionInput,
    UserInviteInput,
} from "@/graphql/custom";
import { App, Decision } from "@/graphql/API";
import PermissionsEditor from "@/components/users/PermissionsEditor.vue";
import AppDirectory from "@/components/apps/AppDirectory.vue";

@Component({
    components: {
        PermissionsEditor,
        AppDirectory,
    },
})
export default class UserEmailInput extends Vue {
    @Prop()
    workId!: number;

    @Prop()
    errorThrown!: boolean;

    private inputEmailList = "";
    private nameList = "";
    private isFormValid = false;
    private loading = false;

    private app: App | null = null;
    private decision: Decision | null = null;

    private url = "";
    private permissions: UpdatePermissionInput | null = null;
    private defaultPerm: UpdatePermissionInput = {
        read: true,
        write: true,
        admin: false,
        owner_only: false,
        fc_r: true,
        fc_w: true,
        vp_r: true,
        vp_w: true,
        fi_r: true,
        fi_w: true,
        ch_r: true,
        ch_w: true,
        va_r: true,
        va_w: true,
        sc_r: true,
        sc_w: true,
    };

    mounted(): void {
        this.permissions = { ...this.defaultPerm };
    }

    private clear(): void {
        this.inputEmailList = "";
        this.nameList = "";
        this.url = "";
        this.app = null;
        this.decision = null;
        this.permissions = { ...this.defaultPerm };
        if (this.$refs.form) (this.$refs.form as HTMLFormElement).reset();
    }

    private checkEmail(val: string): boolean {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(val);
    }

    private rules = {
        emailList: (val: string) => {
            if (val && val.length > 0) {
                return true;
                /*
                    This would split the text into an array and check 
                    any items that aren't empty and tell the user it is
                    not an email. Currently disabled
                */
                /*
                return val
                    .trim()
                    .split(/\r?\n/)
                    .reduce((acc: string | boolean, item: string) => {
                        if (acc != true) {
                            return acc;
                        } else if (item.length == 0) {
                            return true;
                        } else if (this.checkEmail(item)) {
                            return true;
                        } else {
                            return `${item} is not an email`;
                        }
                    }, true);
                */
            } else {
                return "Email(s) required";
            }
        },
    };

    private async splitList(list: string | null): Promise<string[]> {
        if (list && list.length > 0) {
            return list
                .trim()
                .split(/\r?\n/)
                .map((item) => item.trim());
        } else {
            return [];
        }
    }

    private async addUser(): Promise<void> {
        this.loading = true;
        if (this.permissions && this.workId) {
            const permInput: PermissionInviteInput = {
                ...this.permissions,
                workspace_id: -1,
            };
            const emails = await this.splitList(this.inputEmailList);
            const names = await this.splitList(this.nameList);

            if (emails.length) {
                const users = await this.createUserInputs(emails, names);
                if (users.length) {
                    let inviteUser: InviteUserLInput = {
                        users: users,
                        permission: permInput,
                        url: `ws/${this.workId}/?${
                            this.app ? "&app=" + this.app.id + "&" : ""
                        }p_code=`,
                    };
                    this.$emit("user-email", inviteUser);
                }
            }
        } else {
            console.log("Error: No workspace set");
        }
        this.loading = false;
        this.clear();
    }

    private async createUserInputs(
        emails: string[],
        names: string[]
    ): Promise<UserInviteInput[]> {
        if (emails.length) {
            return emails
                .map((item, index) => {
                    return {
                        email: item,
                        name: names.length > index ? names[index] : "",
                    };
                })
                .filter((user) => {
                    return this.checkEmail(user.email);
                });
        } else {
            return [];
        }
    }

    private changePermissions(permissions: UpdatePermissionInput): void {
        this.permissions = { ...permissions };
    }

    private toggleApp(payload: { app: App; decision: Decision }): void {
        if (this.app && this.app.id == payload.app.id) {
            this.app = null;
            this.decision = null;
        } else {
            this.app = payload.app;
            this.decision = payload.decision;
        }
    }

    @Watch("errorThrown")
    private onErrorThrown() {
        if (this.errorThrown) {
            this.clear();
            this.loading = false;
            this.$emit("error-resolved");
        }
    }
}
