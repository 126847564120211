
import { Vue, Component } from "vue-property-decorator";
import { Auth } from "aws-amplify";
import PasswordInput from "@/components/ui/PasswordInput.vue";
import { Rules } from "@/helpers/FormRules";

@Component({
    components: {
        PasswordInput,
    },
})
export default class Login extends Vue {
    private formValid = false;
    private loading = false;
    private email = "";
    private password = "";
    private errorMessage = "";

    private rules = Rules;

    private updatePassword(value: string) {
        this.password = value;
    }

    private async login(): Promise<void> {
        if (!this.loading) {
            this.$emit("loading-start");
            this.loading = true;
            try {
                await Auth.signIn(this.email.trim(), this.password);
                this.$emit("login-link", null);
            } catch (error: any) {
                this.setErrorMsg(error);
                /* 
                    Load end called in BaseView if login succesful 
                    - Only called in case of error
                */
                this.$emit("loading-end");
            }
            this.loading = false;
        }
    }

    private setErrorMsg(error: any): void {
        console.log(error);
        this.errorMessage = error;
        setTimeout(() => (this.errorMessage = ""), 3000);
    }
}
