
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Decision, App } from "@/graphql/API";

import Decisions from "@/store/modules/Decisions";
import Apps from "@/store/modules/Apps";

const decisionsModule = getModule(Decisions);
const appsModule = getModule(Apps);

@Component({
    components: {},
})
export default class AppDirectory extends Vue {
    @Prop()
    selected!: number;

    private appList: { [id: number]: App[] } = {};
    private openId: number | null = null;
    private loading: { [id: number]: boolean } = {};

    get decisions(): Decision[] {
        return decisionsModule.decisionsList;
    }

    private async toggleId(id: number): Promise<void> {
        if (this.openId == id) {
            this.openId = null;
        } else {
            Vue.set(this.loading, id, true);
            this.openId = id;
            const apps = await appsModule.getApps(id);
            Vue.set(this.appList, id, apps);
            Vue.set(this.loading, id, false);
        }
    }
}
